import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';

//引入axios
import axios from 'axios';
Vue.prototype.$axios = axios;

import globalVariable from '@/static/js/global_variable';
import common from '@/static/js/common';
//引入全局变量
Vue.prototype.GLOBAL = globalVariable
Vue.prototype.CommonJs = common

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
