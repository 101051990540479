<template>
  <div class="frame">
    <div class="yike-menu scrollbar" :style="{ width: menuWidth + 'px' }">
      <div class="yike-menu-inner">
        <div class="logo" :style="{ paddingLeft: ptLeft + 'px' }">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconlogo2"></use>
          </svg>
          <div :class="{ close: menuWidth == 92 }" class="info">
            <p class="name">逸刻时光</p>
            <p class="tell">照顾好自己，休息一会</p>
            <!-- <p class="tell">QQ：1334785356</p>
            <p class="tell">WeChat：yikeyikech</p> -->
          </div>
        </div>
        <div class="nav">
          <div class="nav-menu">
            <p class="title" :class="{ close: menuWidth == 92 }">菜单</p>
            <div
              class="nav-li"
              v-for="(item, index) in nav"
              :key="index"
              :class="item.path == nowclick ? 'active' : 'disactive'"
              @click="selectMenu(item.path)"
            >
              <div class="nav-li-icon">
                <svg class="icon" aria-hidden="true">
                  <use :xlink:href="item.icon"></use>
                </svg>
              </div>
              <p class="nav-li-name" :class="{ close: menuWidth == 92 }">
                {{ item.name }}
              </p>
            </div>
          </div>
          <div class="nav-menu" v-if="product.length > 0">
            <p class="title" :class="{ close: menuWidth == 92 }">产品</p>
            <!-- <div class="product-li" v-for="(item, index) in product" :key="index"
              :class="index == producted ? 'active' : 'disactive'" @click="goProduct(item.path)"> -->
            <div
              class="product-li"
              v-for="(item, index) in product"
              :key="index"
              :class="index == producted ? 'active' : 'disactive'"
            >
              <div class="product-li-icon">
                <!-- <svg class="icon" aria-hidden="true">
                  <use :xlink:href="item.icon"></use>
                </svg> -->
                <img src="../static/images/lyqicon.png" v-show="index == 0" />
                <img src="../static/images/elsfk.png" v-show="index == 1" />
                <img src="../static/images/design.png" v-show="index == 2" />
              </div>
              <p class="product-li-name" :class="{ close: menuWidth == 92 }">
                {{ item.name }}
              </p>
            </div>
          </div>
        </div>
        <div class="yike-theme" :style="{ paddingLeft: thLeft + 'px' }">
          <yk-switch v-model="checked" @change="onChange" />
          <p class="theme-title" :class="{ close: menuWidth == 92 }">
            深夜模式
          </p>
        </div>
      </div>
      <div class="widchange" :style="{ width: menuWidth + 'px' }" @click="menuChange()">
        <svg class="icon" aria-hidden="true">
          <use :xlink:href="widChange"></use>
        </svg>
      </div>
    </div>
  </div>
</template>
<script>
import { nav, product } from '../static/js/yike';
import ykSwitch from './ykSwitch.vue';

export default {
  data: function() {
    return {
      nav,
      product,
      menuWidth: 256,
      obj: '',
      aa: undefined, //是否暗黑模式
      widChange: '#iconshousuo', //  改变宽度
      nowclick: '/', //当前选择菜单
      producted: 0,
      ptLeft: 32,
      thLeft: 32,
      checked: false,
    };
  },
  computed: {},
  components: {
    ykSwitch,
  },
  created: function() {
    this.onThemeListen();
    // this.getPath();
  },
  methods: {
    //主题切换
    theme(obj) {
      for (let key in obj) {
        document.getElementsByTagName('body')[0].style.setProperty(`--${key}`, obj[key]);
      }
    },
    onChange(checked) {
      if (checked) {
        this.obj = this.$store.state.themeB;
      } else {
        this.obj = this.$store.state.themeA;
      }
      this.theme(this.obj);
    },
    //监听系统主题变化
    onThemeListen() {
      let match = window.matchMedia('(prefers-color-scheme: dark)').matches;
      if (match) {
        // console.log("iis");
        this.obj = this.$store.state.themeB;
        this.checked = true;
      } else {
        // console.log("nno");
        this.obj = this.$store.state.themeA;
        this.checked = false;
      }
      this.theme(this.obj);
      // console.log(window.matchMedia('(prefers-color-scheme: dark)').matches)
      if (window.matchMedia) {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
          let status = e.matches ? true : false;
          if (status) {
            // console.log("is");
            this.obj = this.$store.state.themeB;
            this.checked = true;
          } else {
            // console.log("no");
            this.obj = this.$store.state.themeA;
            this.checked = false;
          }
          this.theme(this.obj);
        });
      }
    },
    //修改菜单宽度
    menuChange() {
      if (this.menuWidth == 92) {
        this.menuWidth = 256;
        this.ptLeft = 32;
        this.thLeft = 32;
        this.widChange = '#iconshousuo';
      } else {
        this.menuWidth = 92;
        this.ptLeft = 16;
        this.thLeft = 24;
        this.widChange = '#iconzhankai';
      }
      this.$emit('menuwid', this.menuWidth);
    },
    //切换菜单
    selectMenu(path) {
      this.CommonJs.goTop();
      if (path != this.nowclick) {
        this.$router.push(path);
      }
    },
    goProduct(path) {
      window.open(path, '_blank');
    },
    getPath() {
      let path = this.CommonJs.routerSelect() + this.$route.path;
      this.nowclick = path;
      this.$router.push(path);
    },
  },
  watch: {
    //监听地址栏变化
    $route(to, from) {
      console.log('aaaa');
      this.nowclick = to.path;
    },
  },
};
</script>
<style scoped lang="less">
.yike-menu {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  background-color: @bg-color-top;
  transition: all @animation-duration-slow;
  overflow: auto;
  z-index: 1;

  //动态管理菜单了的展开和收起
  .close {
    opacity: 0;
    display: none;
    // transition: all @animation-duration-slow;
  }

  .info {
    padding-left: 12px;
  }

  .logo {
    padding-top: 32px;
    padding-bottom: 32px;
    transition: all @animation-duration-slow;
    display: flex;

    .icon {
      width: 60px;
      height: 37px;
      color: @primary-color;
    }

    .name {
      font-size: 20px;
      font-weight: 700;
      color: @font-color-top;
      line-height: 1.1;
      // padding-top: 20px;
      transition: all @animation-duration-slow;
      white-space: nowrap;
    }

    .tell {
      font-size: @font-size-sm;
      color: @font-color-bottom;
      line-height: 1.5;
      transition: all @animation-duration-slow;
      white-space: nowrap;
    }
  }

  .nav {
    padding-top: 8px;
    padding-left: 32px;
    transition: all @animation-duration-slow;

    .nav-menu {
      padding-bottom: 30px;
    }

    .title {
      font-size: @font-size-base;
      color: @font-color-bottom;
      line-height: 1.5;
      padding-bottom: 10px;
      transition: all @animation-duration-slow;
    }

    .nav-li {
      height: 56px;
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .nav-li-icon {
        border-radius: 10px;
        width: 28px;
        height: 28px;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all @animation-duration-slow;

        .icon {
          width: 14px;
          height: 14px;
          transition: all @animation-duration-slow;
        }
      }

      .nav-li-name {
        padding-left: 16px;
        min-width: 154px;
        font-weight: 700;
        transition: all @animation-duration-slow;
        white-space: nowrap;
      }
    }

    .product-li {
      display: flex;
      height: 56px;
      justify-items: center;
      cursor: pointer;

      .product-li-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
          height: 28px;
        }
      }

      .product-li-name {
        padding-left: 16px;
        line-height: 56px;
        font-weight: 600;
      }
    }

    .active {
      .nav-li-icon {
        background: @primary-color;
        // background-color: @warning-color;
      }

      .icon {
        color: #fff;
      }

      .nav-li-name {
        color: @font-color-top;
      }
    }

    .disactive {
      .nav-li-icon {
        background: @bg-color-other;
      }

      .icon {
        color: @font-color-bottom;
      }

      .nav-li-name {
        color: @font-color-bottom;
      }
    }
  }

  .yike-theme {
    width: 100%;
    position: fixed;
    bottom: 72px;
    display: flex;
    flex-direction: row;
    transition: all @animation-duration-slow;

    .theme-title {
      padding-left: 16px;
      min-width: 120px;
      color: @font-color-bottom;
      transition: all @animation-duration-slow;
    }
  }

  .widchange {
    position: fixed;
    z-index: 10;
    height: 48px;
    box-shadow: 0 -1px 0 0 @bg-color-other;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: @bg-color-top;
    transition: all @animation-duration-slow;

    .icon {
      width: 20px;
      height: 20px;
      color: @font-color-bottom;
    }
  }
}

.scrollbar::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 4px;
  /*高宽分别对应横竖滚动条的尺寸*/
  height: 1px;
}

.scrollbar::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 4px;
  background: @font-color-bottom;
}

.scrollbar::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 4px;
  background: @bg-color-bottom;
}

.nr {
  margin: 20px;
  height: 120px;
  background: #aaa;
  transition: all @animation-duration-slow;
}
</style>
