export default {
  //初始化获取分享类列表
  content(rem, page, display, judge, classlfy, a) {
    rem.$axios.post(rem.GLOBAL.baseUrl + "/shareData", {
      nowpage: page,
      display: display,
      judge: judge,
      gjc: classlfy,
    })
      .then(function (res) {
        let num = display * (page - 1);//当前第一个位置
        let data = res.data;
        if (data.status == 200) {
          for (let i = 0; i < data.result.length; i++) {
            a[num + i] = data.result[i]
            //console.log(data.result[i])
            rem.$axios.post(rem.GLOBAL.baseUrl + '/likeCount', {
              id: data.result[i]._id,
              ip: 1,
            })
              .then(function (resl) {
                let likes = resl.data;
                if (likes.status == 200) {
                  data.result[i].likes = likes.result;
                  a.splice(num + i, 1, data.result[i]);
                  // a.push(data.result[i]);
                } else {
                  rem.$message.error('访问文章总数后端出错');
                }
                // console.log(a)
              })
              .catch(function (error) {
                rem.$message.error('访问文章总数出错');
              });

            // a.push(data.result[i]);
          }
        } else {
          rem.$message.error('访问文章后端出错');
        }
      })
      .catch(function (error) {
        // console.log(error);
        rem.$message.error('访问文章出错');
      });
  },
  //获取数据总数
  counts(rem, classlfy, judge, tol) {
    rem.$axios.post(rem.GLOBAL.baseUrl + '/shareDataCount', {
      judge: judge,
      gjc: classlfy,
    })
      .then(function (res) {
        let data = res.data;
        if (data.status == 200) {
          tol.push(data.result);
        } else {
          rem.$message.error('访问文章总数后端出错');
        }
      })
      .catch(function (error) {
        rem.$message.error('访问文章总数出错');
      });
  },
  //获取文章喜欢总数
  likeCounts(rem, id, ip, tol) {
    rem.$axios.post(rem.GLOBAL.baseUrl + '/likeCount', {
      id: id,
      ip: ip,
    })
      .then(function (res) {
        let data = res.data;
        if (data.status == 200) {
          tol.push(data.result);
        } else {
          rem.$message.error('访问文章总数后端出错');
        }
      })
      .catch(function (error) {
        rem.$message.error('访问文章总数出错');
      });
  },
  //埋点点击问题
  getVisit(rem, e) {
    rem.$axios({
      url: rem.GLOBAL.baseUrl + '/locayike',
      method: 'POST',
      data: { types: e },
    }).then((response) => {
      // console.log('埋点')
    }).catch((error) => {
      console.log(error)       //请求失败返回的数据
    })
  },
  //获取点击数字
  countVisit(rem, i, e) {
    rem.$axios({
      url: rem.GLOBAL.baseUrl + '/countvisit',
      method: 'POST',
      data: { types: i },
    }).then((response) => {
      let rest = response.data    //这里使用了ES6的语法
      if (rest.success) {
        e.push(rest.rest);
      }
    }).catch((error) => {
      console.log(error)       //请求失败返回的数据
    })
  },
  //滚回顶部
  goTop() {
    var timer;			// 回到顶部方法
    clearInterval(timer);
    timer = setInterval(function () {
      let curHeight = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;  // 得到当前高度
      var now = curHeight;
      var speed = (0 - now) / 7;								// 随着高度减速
      speed = speed > 0 ? Math.ceil(speed) : Math.floor(speed);

      if (curHeight === 0) {							//当前高度为零,停止这次计时器
        clearInterval(timer);  							// C1
      }
      document.documentElement.scrollTop = curHeight + speed;			//直接给高度赋值,会调用needtotop方法
      document.body.scrollTop = curHeight + speed;				//谷歌的
      stop = false;								// A
    }, 20);
  },
  routerSelect(){
    if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)){
      // console.log('屏幕测试1')
      return 'm';
    }else{
      // console.log('屏幕测试2');
      return '';
    }
  },
}