<template>
  <div id="home" :style="{ paddingLeft: menuWidth + 'px' }">
    <Menu @menuwid="menuWid"/>
    <Tobar/>
    <router-view></router-view>
    <Footer/>
  </div>
</template>

<script>
import Menu from '../components/menu.vue';
import Tobar from '../components/tobar.vue';
import Footer from '../components/footer.vue';

export default {
  name: 'Home',
  components: {
    Menu,
    Tobar,
    Footer,
  },
   data: function () {
    return {
      menuWidth: 256,
    };
  },
  created: function () {
    this.getPath();
  },
  methods: {
    menuWid(e){
     this.menuWidth = e;
    },
     getPath(){
      //  console.log('aaabbcc')
       //判断移动端还是pc端
      let path = this.CommonJs.routerSelect()+this.$route.path;
      this.$router.push(path);
    },
  },
  watch: {
    
  }
}
</script>

<style lang="less" scoped>

#home{
  transition: all @animation-duration-slow;
  margin: @padding-base @padding-bg 0;
}
 .title{
    background: #00aaee;
  }
  .box{
    width: 400px;
    height: 200px;
    background: rgb(31, 199, 211);
  }
</style>

