//首页数据
export const nav = [
    {
        name: '首页',
        icon: '#iconIcon-shouye',
        path: '/'
    },
    {
        name: '个人分享',
        icon: '#iconIcon-wenzhang',
        path: '/share'
    },
    {
        name: '项目产品',
        icon: '#iconIcon-chanpin',
        path: '/product'
    },
    {
        name:'技术交流',
        icon: '#iconIcon-jishu',
        path: '/exchange'
    },
    {
        name:'资源下载',
        icon: '#iconkucunziliao',
        path: '/resource'
    },
    {
        name:'关于',
        icon: '#iconIcon-wode',
        path:'/about'
    }
]

export const product = [
    {
        name:'留言墙',
        icon: '#iconyike-liaotian',
        path:'https://www.huohuo90.com:3002/',
        // color: '#FFE432',
    },
    {
        name:'俄罗斯方块',
        icon: '#iconIcon-jishu',
        path:'https://www.huohuo90.com:3004/',
    },
    {
        name:'Yike Design',
        icon: '#iconIcon-jishu',
        path:'https://www.huohuo90.com:3005/',
    }
]

export const module = [
    {
       color:'#22BF87',
       icon: '#iconIcon-wenzhang',
       data: 0,
       title: '个人分享',
       explain: '我的日常记录，平时所想、记录旅游、摄影…',
       src: '/share',
    },
    {
        color:'#A048FA',
        icon: '#iconIcon-chanpin',
        data: 0,
        title: '项目&文件',
        explain: '设计及开发项目总结，不限于开发完成的项目，包括一些产品概念...',
        src: '/product',
     },
     {
        color:'#FF7551',
        icon: '#iconIcon-jishu',
        data: 0,
        title: '技术交流',
        explain: '小白在开发过程中或多或少会遇到并解决一些问题，站在设计的角度去看开发问题。',
        src: '/exchange',
     },
    
]

//分类
export const share = [
    {
        name:'文章',
        id:'wenzhang'
    },
    {
        name:'摄影',
        id:'sheying'
    },
    {
        name:'随记',
        id:'suiji'
    }
]

//项目资源
export const pranddo = [
    {
        name:'项目',
        id:'xiangmu'
    },
    {
        name:'资源',
        id:'ziyuan'
    },
]

export const classlfy = [
    ["我", "所见", "所思", "所叙", "其他"],
    ["产品", "练习", "临摹", "平面", "其他"],
    ["风景", "静物", "人物", "人文", "其他"],
    ["psd","sketch","xd","pdf","jpg/png","zip","其他"]
]