<template>
  <div class="notes-photo">
    <div class="n-p-top" @click="closePh">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#iconguanbi"></use>
      </svg>
      <p class="n-page" v-if="nowpic > -1">
        <span class="n-page-n">{{ nowpic + 1 }}</span>
        /
        {{ picarr.length }}
      </p>
    </div>
    <div class="n-p-bg" @click="closePh"></div>
    <div v-if="nowpic > -1">
      <div class="left icons" @click="changePhoto('l')">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#iconzuo"></use>
        </svg>
      </div>
      <div class="right icons" @click="changePhoto('r')">
        <svg class="icon" aria-hidden="true">
          <use xlink:href="#iconyou"></use>
        </svg>
      </div>
      <img :src="picarr[nowpic]" class="n-p-m" />
    </div>
  </div>
</template>
<script type="text/javascript">
import s from "../../static/js/myfunc.js";
export default {
  data() {
    return {
      tops: false,
      aa: true,
    };
  },
  computed: {},
  props: {
    nowpic: {
      // 当前第几张
      type: Number,
      default: 0,
    },

    picarr: {
      // 图片数组
      type: Array,
      default: [],
    },
  },
  methods: {
    //关闭
    closePh() {
      this.$emit("clooseDt");
      console.log("这个是" + this.picarr);
    },

    //切换日志大图
    changePhoto(e) {
      if (e == "l" && this.nowpic > 0) {
        this.nowpic--;
      } else if (e == "r" && this.nowpic < this.picarr.length - 1) {
        this.nowpic++;
      }
      // console.log(this.isPhoto);
    },
  },
  mounted: function () {
    s.addEvent(window, "scroll", this.aaa);
  },
};
</script>
<style lang="less" scoped>
.notes-photo {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1001;
  overflow: auto;
  .n-p-bg {
    position: fixed;
    top: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, 0.9);
  }
  .n-p-top {
      position: absolute;
      top: 0px;
      left: 0;
      height: 44px;
      width: 100%;
      z-index: 100;
      .n-page {
        text-align: center;
        color: #fff;
        line-height: 44px;
        .n-page-n {
          font-size: 20px;
        }
      }
      .icon {
        color: #fff;
        position: absolute;
        left: 20px;
        top: 12px;
        font-size: 24px;
      }
    }
  .n-p-m {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    margin: auto;
    max-height: 96%;
    max-width: 100%;
  }
  .icons {
    position: absolute;
    cursor: pointer;
    z-index: 2;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    background: rgba(40, 40, 40, 0.8);
    text-align: center;
    line-height: 52px;
    box-sizing: border-box;
    opacity: 0.8;
    transition: all @animation-duration-slow;
    .icon {
      color: rgba(255, 255, 255, 1);
      font-size: @font-size-bg2;
    }
    &:hover {
      opacity: 1;
    }
  }
  .left {
    right: 100px;
    bottom: 24px;
    padding-right: 2px;
  }
  .right {
    right: 24px;
    bottom: 24px;
    margin: auto;
    padding-left: 2px;
  }
}
</style>