
<script>
import s from '../static/js/myfunc.js'
export default {
  data() {
    return {
      picks: false,
      loadcolor: '',
    }
  },
  props: {
    modelValue: {
      type: Boolean,
      default: null,
    },
  },
  computed: {

  },
  methods: {
    changeColor: function () {
      if (this.modelValue != null) {
        this.picks = this.modelValue;
      }
      if (this.picks) {
        this.loadcolor = 'primary';
      } else {
        this.loadcolor = 'default'
      }
    },
    change: function () {
      this.picks = !this.picks;
      console.log('a');
      this.changeColor();
      this.$emit("update:modelValue", this.picks);
      this.$emit("change", this.picks);
    },

  },
  mounted: function () {
    this.changeColor();
  },
};
</script>

<template>
  <div class="yk-switch">
    <label @click="change" :class="{ darkm: picks }">
      <i></i>
    </label>
  </div>
</template>

<style lang="less" scoped>
label {
  display: block;
  width: 40px;
  height: 22px;
  margin: 0px auto;
  border-radius: 100px;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  background-color: @bg-color-bottom;
  cursor: pointer;

  &:hover {
    opacity: 0.9;

    i {
      box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.2);
      transform: scale(1.01);
    }
  }

  &:active {
    i {
      width: 20px;
      box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.2);
    }
  }

  i {
    height: 16px;
    width: 16px;
    background: #fff;
    display: inline-block;
    border-radius: 100px;
    margin-top: 3px;
    margin-left: 3px;
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    pointer-events: none;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    position: relative;

    .m {
      position: absolute;
      transform: scale(0.9);
      top: -3.5px;
      left: 1px;
    }

    .s {
      position: absolute;
      transform: scale(0.7);
      top: -3px;
      left: -3.5px;
    }
  }
}

.darkm {
  background-color: @primary-color;

  &:active {
    i {
      margin-left: 16px;
    }
  }

  i {
    margin-left: 20px;
  }
}
</style>