<template>
  <div id="mpd" ref="photo">
    <div class="card">
      <div
        class="li-div"
        v-for="(rem, index) in articles"
        :key="index"
        @click="detail(rem._id)"
      >
        <div class="img-main">
          <div class="photo-img">
            <img :src="GLOBAL.baseUrl + '/cover/' + rem.icon" />
          </div>
        </div>
        <p class="title">{{ rem.name }}</p>
        <div class="work-other">
          <p class="type">
            <span>{{ classlfy[rem.types][rem.classlfy] }}</span>
          </p>
          <p class="dates">{{ dtime(rem.time) }}</p>
          <div class="msg">
            <p class="zan">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#iconIcon-zan1"></use>
              </svg>
              {{ rem.likes }}
            </p>
            <p class="look">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#iconchakanyanjingshishifenxi"></use>
              </svg>
              {{ rem.times }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div v-if="articles.length == 0" class="none">
      <p>还没有内容～</p>
    </div>
    <div class="mores">{{ tobottom }}</div>
  </div>
</template>
<script type="text/javascript">
import s from "../../static/js/myfunc.js";
import elementR from "element-resize-detector";
import { classlfy } from "../../static/js/yike";
export default {
  data() {
    return {
      articles: [],
      art: [],
      judge: 6,
      nowpage: 1,
      gjc: -1,
      total: [],
      tobottom: "",
      classlfy,
    };
  },
  components: {},
  props: {
    display: {
      // 每页显示条数
      type: Number,
      default: 10,
    },
  },
  computed: {},
  created: function () {
    this.datas(this.nowpage);
    this.totals();
  },
  methods: {
    //时间转换
    dtime: function (time) {
      return s.changeTime2(time);
    },
    datas: function (nowpg) {
      //数据项
      // this.articles = [];
      this.CommonJs.content(
        this,
        nowpg,
        this.display,
        this.judge,
        this.gjc,
        this.articles
      );
    },
    totals: function () {
      this.CommonJs.counts(this, this.gjc, this.judge, this.total);
    },
    //点击进入详情
    detail: function (id) {
      let _this = this;
      _this.$axios
        .post(_this.GLOBAL.baseUrl + "/timesAdd", {
          id: id,
        })
        .then(function (res) {
          var data = res.data;
          if (data.status == 200) {
          } else {
            _this.$message.error("访问详情后端出错");
          }
        })
        .catch(function (error) {
          // console.log(error);
          _this.$message.error("访问详情数出错");
        });
      _this.$emit("detail", id);
    },
    //加载更多
    morepage: function () {
      var scrolltop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrolltop + window.innerHeight >= document.body.offsetHeight - 100) {
        if (this.nowpage < Math.ceil(this.total[0] / this.display + 1)) {
          this.tobottom = "";
          this.nowpage++;
          this.datas(this.nowpage);
        } else {
          this.tobottom = "已到底部...";
        }
      }
    },
  },
  mounted() {
    s.addEvent(window, "scroll", this.morepage);
  },
};
</script>
<style lang="less" scoped>
#mpd {
  width: 100%;
  padding-bottom: @padding-base;
  .card {
    overflow: hidden;
    padding: 0 24px;
    .li-div {
      display: block;
      text-decoration: none;
      overflow: hidden;
      box-sizing: border-box;
      margin-bottom: @padding-base;
    }
    .img-main {
      position: relative;
      border-radius: @radius-sm;
      overflow: hidden;
      img {
        width: 100%;
      }
    }

    .title {
      font-weight: 700;
      margin-top: 12px;
      box-sizing: border-box;
      color: @font-color-top;
      transition: all @animation-duration-slow;
    }
  }
  .mores {
    text-align: center;
    padding-top: @padding-base;
    color: @font-color-bottom;
  }
  .work-other {
    padding-top: @padding-sm1;
    display: flex;
    justify-content: space-between;
    width: 100%;
    p {
      font-size: @font-size-sm;
      transition: all @animation-duration-slow;
    }
    .type {
      color: @font-color-base;
    }
    .dates {
      padding-left: @padding-sm1;
      flex: 1;
      color: @font-color-bottom;
    }
    .msg {
      display: flex;
      align-self: flex-end;
      color: @font-color-bottom;
    }
    .zan {
      padding-right: @padding-sm1;
    }
    .icon {
      padding-right: 2px;
    }
  }
}
</style>