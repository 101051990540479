<template>
  <div class="mmenu" :style="{ left: ptLeft }">
    <svg class="cloose-i" aria-hidden="true" @click="changeM">
      <use xlink:href="#iconguanbi1"></use>
    </svg>
    <div class="yike-menu">
      <div class="yike-menu-inner">
        <div class="logo">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconlogo2"></use>
          </svg>
          <div class="info">
            <p class="name">逸刻时光</p>
            <p class="tell">QQ：1334785356</p>
            <p class="tell">WeChat：yikeyikech</p>
          </div>
        </div>
        <div class="nav">
          <div class="nav-menu">
            <p class="title">菜单</p>
            <div class="nav-li" v-for="(item, index) in nav" :key="index"
              :class="'/m' + item.path == nowclick ? 'active' : 'disactive'" @click="selectMenu('/m' + item.path)">
              <p class="nav-li-name">
                {{ item.name }}
              </p>
            </div>
          </div>
          <div class="nav-menu" v-if="product.length > 0">
            <p class="title">产品</p>
            <div class="nav-li" v-for="(item, index) in product" :key="index"
              :class="index == producted ? 'active' : 'disactive'" @click="goProduct(item.path)">
              <p class="nav-li-name">
                {{ item.name }}
              </p>
            </div>
          </div>
        </div>
        <div class="yike-theme">
          <yk-switch v-model="aa" @change="onChange"/>
          <p class="theme-title">深夜模式</p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { nav, product } from "../../static/js/yike";
import ykSwitch from "../ykSwitch.vue"

export default {
  data: function () {
    return {
      nav,
      product,
      menuWidth: 256,
      obj: "",
      aa: undefined, //是否暗黑模式
      nowclick: '/', //当前选择菜单
      producted: -1,
      checks: false,
      ptLeft: '-100%',
    };
  },
  props: {
  },
  components: {
    ykSwitch,
  },
  computed: {},
  created: function () {
    this.obj = this.$store.state.themeA;
    this.theme(this.obj);
    this.getPath();
  },
  methods: {
    //主题切换
    theme(obj) {
      for (let key in obj) {
        document
          .getElementsByTagName("body")[0]
          .style.setProperty(`--${key}`, obj[key]);
      }
    },
    onChange(checked) {
      this.checks = checked;
      //   console.log(checked);
      if (checked) {
        this.obj = this.$store.state.themeB;
      } else {
        this.obj = this.$store.state.themeA;
      }
      this.theme(this.obj);
    },
    //切换菜单
    selectMenu(path) {
      this.CommonJs.goTop();
      if (path != this.nowclick) {
        this.changeM();
        this.nowclick = path;
        this.$router.push(path);
      }
    },
    //关闭导航栏
    changeM() {
      if (this.ptLeft == '0') {
        this.ptLeft = '-100%';
      } else {
        this.ptLeft = '0';
      }
      // console.log(this.ptLeft);
    },
    getPath() {
      let path = this.$route.path;
      this.nowclick = path;
      //   console.log(this.nowclick);
    },
    goProduct(path) {
      window.open(path, '_blank');
    },
  },
};
</script>
<style scoped lang="less">
.mmenu {
  position: fixed;
  top: 0;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background-color: @bg-color-top;
  transition: all @animation-duration-slow;
  z-index: 100;

  .cloose-i {
    position: absolute;
    width: 30px;
    height: 30px;
    fill: @font-color-bottom;
    right: 20px;
    top: 20px;
  }

  .yike-menu {
    width: 100%;
    box-sizing: border-box;
    background-color: @bg-color-top;
    height: 100%;
    overflow: auto;

    .logo {
      padding-top: 80px;
      padding-left: 32px;
      padding-bottom: 32px;
      display: flex;
      transition: all @animation-duration-slow;

      .icon {
        width: 100px;
        height: 72px;
        color: @primary-color;
      }

      .info {
        padding-left: 16px;
      }

      .name {
        font-size: 20px;
        font-weight: 700;
        color: @font-color-top;
        line-height: 1.5;
        transition: all @animation-duration-slow;
      }

      .tell {
        font-size: @font-size-base;
        color: @font-color-bottom;
        line-height: 1.5;
        transition: all @animation-duration-slow;
      }
    }

    .nav {
      padding-top: 8px;
      padding-left: 32px;
      transition: all @animation-duration-slow;

      .nav-menu {
        padding-bottom: 30px;
      }

      .title {
        font-size: @font-size-base;
        color: @font-color-bottom;
        line-height: 1.5;
        padding-bottom: 10px;
        transition: all @animation-duration-slow;
      }

      .nav-li {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        .nav-li-name {
          position: relative;
          z-index: 10;
          font-size: 28px;
          line-height: 70px;
          font-weight: 600;
          color: @font-color-top;
          transition: all @animation-duration-slow;
          white-space:nowrap; 
        }
      }

      .active {
        .nav-li-name {
          &::before {
            position: absolute;
            bottom: 18px;
            z-index: -1;
            content: "";
            display: block;
            width: 100%;
            height: 10px;
            background: @primary-color;
          }
        }
      }
    }

    .yike-theme {
      padding-left: 32px;
      padding-bottom: 40px;
      display: flex;
      flex-direction: row;
      transition: all @animation-duration-slow;

      .theme-title {
        padding-left: 16px;
        min-width: 120px;
        color: @font-color-bottom;
        transition: all @animation-duration-slow;
      }
    }
  }
}
</style>