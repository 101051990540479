<template>
  <div id="home">
    <Menu ref="child"/>
    <Tobar @cddq="chleft"/>
    <router-view></router-view>
    <!-- <Footer/> -->
  </div>
</template>

<script>
import Menu from '../../components/mobile/menu.vue';
import Tobar from '../../components/mobile/mtobar.vue';
// import Footer from '../../components/footer.vue';

export default {
  name: 'Home',
  components: {
    Menu,
    Tobar,
    // Footer,
  },
   data: function () {
    return {
      menuWidth: 256,
      ptleft: '-100%',
    };
  },
  created: function () {
    // this.getPath();
  },
  methods: {
     getPath(){
         console.log('aaaabbdd')
       //判断移动端还是pc端
      let path = this.CommonJs.routerSelect()+this.$route.path;
      this.$router.push(path);
      this.ptleft = '-100%';
    },
    chleft(){
        this.$refs.child.changeM();
    }
  },
  watch: {
    
  }
}
</script>

<style lang="less" scoped>

#home{
  transition: all @animation-duration-slow;
}
 .title{
    background: #00aaee;
  }
  .box{
    width: 400px;
    height: 200px;
    background: rgb(31, 199, 211);
  }
</style>

