<template>
  <div class="notes" ref="notes">
    <div
      v-for="(html, index) in htmls"
      :key="index"
      class="notes-list"
      :class="carcClass"
    >
      <p class="time" v-html="time(html.time)"></p>
      <div class="notes-list-con">
        <p class="title">{{ html.title }}</p>
        <p class="content"><span v-html="html.content"></span></p>
        <div class="imgs" v-if="html.imgs[0] != ''">
          <div class="img" v-for="(img, indexs) in html.imgs" :key="indexs">
            <p
              :style="{
                'background-image':
                  'url(' + GLOBAL.baseUrl + '/diary/' + img + ')',
                'background-size': 'cover',
              }"
              @click="notesPhoto(index, indexs)"
            ></p>
          </div>
        </div>
      </div>
    </div>
    <div v-if="htmls.length == 0" class="none">
      <p>还没有内容～</p>
    </div>
    <p class="mores">{{ tobottom }}</p>
  </div>
</template>
<script type="text/javascript">
import s from "../static/js/myfunc.js";
import elementR from "element-resize-detector";
export default {
  data() {
    return {
      htmls: [],
      imgs: [],
      nowphoto: "", //当前选择图片
      photoclick: false, //点击照片
      photoindex: "", //点击照片当前排位
      nowdiary: "", //当前日志
      num: 1,
      display: 6,
      tobottom: "",
      isbottom: false,
      carcClass: "one",
    };
  },
  computed: {},
  created: function () {
    this.getDairy(1);
  },
  methods: {
    time: function (num) {
      let arr = s.changeTime2(num).match(/\w/g);
      let y = "",
        m = "",
        d = "";
      for (let i = 0; i < arr.length; i++) {
        if (i <= 3) {
          y += arr[i];
        } else if (i > 3 && i <= 5) {
          m += arr[i];
        } else {
          d += arr[i];
        }
      }
      return (
        "<p>" +
        y +
        "</p><p>" +
        m +
        '月</p><p style="font-size:24px;">' +
        d +
        "</p>"
      );
    },
    //获取
    getDairy: function (i) {
      var _this = this;
      _this.$axios
        .post(_this.GLOBAL.baseUrl + "/shareNotes", {
          nowpage: i,
          display: _this.display,
        })
        .then(function (res) {
          var data = res.data;
          if (data.status == 200) {
            if (data.result.length > 0) {
              _this.num++;
              var html = data.result;
              //处理取出的数据
              for (var i = 0; i < html.length; i++) {
                html[i].imgs = html[i].imgs.split(",");
                html[i].content = html[i].content.replace(/[\r\n]/g, "</br>");
              }
              //concat() 方法用于连接两个或多个数组
              _this.htmls = _this.htmls.concat(html);
              console.log('a')
            } else {
              _this.isbottom = true;
            }
          } else {
            console.log("访问日志后端出错");
          }
        })
        .catch(function (error) {
          // console.log(error);
          console.log("访问日志出错");
        });
    },
    //监听元素宽度变化
    watchSize() {
      const _this = this;
      const erd = elementR();
      erd.listenTo(this.$refs.notes, (element) => {
        var width = element.offsetWidth;
        var height = element.offsetHeight;
        _this.$nextTick(() => {
          // 这里填写监听改变后的操作
          if (width > 400) {
            this.carcClass = "one";
          } else {
            this.carcClass = "two";
          }
        });
      });
    },
    //加载更多
    morepage: function () {
      var _this = this;
      var scrolltop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrolltop + window.innerHeight >= document.body.offsetHeight - 100) {
        if (_this.isbottom) {
          _this.tobottom = "已到底部...";
        } else {
          _this.tobottom = "";
          //this.lotties = false;
          this.getDairy(_this.num);
        }
      }
    },
    //点击进入图片详情
    notesPhoto: function (i, j) {
      // console.log(i)
      this.$emit("notePhoto", this.htmls[i], j);
    },
  },
  mounted: function () {
    // this.getDairy();
    this.watchSize();
    s.addEvent(window, "scroll", this.morepage);
  },
};
</script>
<style lang="less" scoped>
.notes {
  width: 100%;
}
.notes-list {
  display: flex;
  padding-bottom: @padding-base;
  .time {
    // width: 80px;
    color: @font-color-bottom;
    font-weight: 700;
    text-align: right;
    padding-top: 2px;
  }
  .title {
    font-size: @font-size-bg3;
    padding-bottom: 8px;
    transition: all @animation-duration-slow;
  }
  .content {
    color: @font-color-base;
    line-height: 2;
    transition: all @animation-duration-slow;
    padding-bottom: 4px;
  }
  .imgs {
    padding-top: @padding-sm1;
  }
}
.one {
  padding-bottom: @padding-base;
  .time {
    padding-right: @padding-sm;
  }
  .content {
    width: 560px;
  }
  .imgs {
    .img {
      width: 100%;
    }
    .img:first-child {
      p {
        width: 330px;
        height: 220px;
        float: left;
        margin-right: @padding-sm1;
        border-radius: @radius-base;
      }
    }
    p {
      float: left;
      width: 60px;
      height: 60px;
      margin: 0 8px 8px 0;
      border-radius: @radius-sm;
      background: @bg-color-top;
    }
  }
}
.two {
  padding-bottom: @padding-base;
  .time {
    padding-right: @padding-sm1;
  }
  .content {
    width: 284px;
  }
  .imgs {
    .img {
      width: 100%;
    }
    .img:first-child {
      p {
        width: 284px;
        height: 192px;
        float: left;
        margin-right: @padding-sm1;
        border-radius: @radius-base;
      }
    }
    p {
      float: left;
      width: 60px;
      height: 60px;
      margin: 0 8px 8px 0;
      border-radius: @radius-sm;
      background: @bg-color-top;
    }
  }
}
.mores {
  text-align: center;
  padding-top: @padding-base;
  color: @font-color-bottom;
}
</style>