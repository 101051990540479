<template>
  <div class="mdet">
    <div class="n-p-bg" @click="closePh">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#iconguanbi"></use>
      </svg>
    </div>

    <div class="details">
      <div class="article">
        <div class="detail-top">
          <p class="title">{{ detail.name }}</p>
          <p class="styles" v-if="detail.types != 2">
            {{ classlfy[detail.types][detail.classlfy] }} / {{ detail.tep }}
          </p>
          <p class="styles" v-if="detail.types == 2">摄影 / {{ classlfy[detail.types][detail.classlfy] }}</p>
          <div class="others">
            <p class="time">{{ detail.time }}</p>
            <div class="msg">
              <p class="zan">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconIcon-zan1"></use>
                </svg>
                {{ likes[0] }}
              </p>
              <p class="look">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconchakanyanjingshishifenxi"></use>
                </svg>
                {{ detail.times }}
              </p>
              <p class="comment">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconIcon-pinglun"></use>
                </svg>
                {{ commentcount }}
              </p>
            </div>
          </div>
        </div>
        <div class="introduc">
          <p>{{ detail.introduc }}</p>
        </div>
        <div class="content" v-html="detail.content" ref="detailCont" v-if="detail.types != 2"></div>
        <div class="meituc" v-html="detail.content" ref="detailCont" v-if="detail.types == 2"></div>
      </div>
      <div class="cont-msg">
        <div :class="isLikes[0] != 0 ? 'active' : 'disactive'" @click="praise">
          <svg class="icon" aria-hidden="true" v-if="isLikes[0] == 0">
            <use xlink:href="#iconIcon-zan1"></use>
          </svg>
          <svg class="icon" aria-hidden="true" v-if="isLikes[0] != 0">
            <use xlink:href="#iconIcon-zan"></use>
          </svg>
          <p>{{ likes[0] }}</p>
        </div>
      </div>
      <div class="detail-bottom">
        <div class="comments">
          <Comment :id="id" :count="commentcount" @commented="addCount"></Comment>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import s from '../../static/js/myfunc.js';
import Comment from './comment';
import otph from '../article.vue';
import { classlfy } from '../../static/js/yike';
export default {
  data: function() {
    return {
      detail: '',
      classlfy,
      imgs: [],
      disp: 'none',
      commentcount: 0, //评论数
      likes: [], //喜欢次数
      isLikes: [],
    };
  },
  components: {
    Comment,
    otph,
  },
  props: {
    id: {
      // 文章id
      type: String,
      default: '0',
    },
  },
  computed: {},
  created: function() {
    this.detialId(this.id);
  },
  updated() {
    //字符串化成数组
    // var photo = this.$refs.detailCont.textContent.split(",");
    // console.log(photo)
    // this.imgs = this.$refs.detailCont.querySelectorAll("img");
    // for (let i = 0; i < this.imgs.length; i++) {
    //   this.imgs[i].addEventListener("load", (ele) => {
    //     let e = ele || window.event;
    //     let target = e.target || e.srcElement;
    //     target.style.maxWidth = "100%";
    //   });
    // }
  },
  methods: {
    //关闭
    closePh() {
      this.$emit('clooseDt');
    },
    //获取文章详情
    detailData(e) {
      let that = this;
      that.disp = 'none';
      this.$axios
        .post(this.GLOBAL.baseUrl + '/detial', {
          id: e,
        })
        .then(function(res) {
          let data = res.data;
          if (data.status == 200) {
            data.result.time = s.changeTime2(data.result.time);
            if (data.result.types == 2) {
              let photo = data.result.content.split(',');
              let pharr = '';
              for (let i = 0; i < photo.length; i++) {
                pharr += '<img src="' + that.GLOBAL.baseUrl + '/photography/' + photo[i] + '" style="width:100%"/>';
              }
              data.result.content = pharr;
            }
            that.detail = data.result;
            that.disp = 'block';
          } else {
            that.$message.error('访问文章详情后端出错');
          }
        })
        .catch(function(error) {
          that.$message.error('访问文章详情出错');
        });
    },
    //获取评论数
    cmmentCount(e) {
      let that = this;
      this.$axios
        .post(this.GLOBAL.baseUrl + '/commentCount', {
          id: e,
        })
        .then(function(res) {
          let data = res.data;
          // console.log(data)
          if (data.status == 200) {
            that.commentcount = data.result;
          } else {
            that.$message.error('访问文章品论数后端出错');
          }
        })
        .catch(function(error) {
          that.$message.error('访问文章品论数出错');
        });
    },
    //增加评论数
    addCount() {
      this.commentcount++;
    },
    //获取赞数及判断是否已经点赞
    likeCount: function(id, ip, e) {
      this.CommonJs.likeCounts(this, id, ip, e);
      // console.log(this.total)
    },
    //点赞
    praise() {
      if (this.isLikes[0] == 0) {
        this.isLikes.splice(0, 1, 1);
        this.likes[0]++;
        let that = this;
        this.$axios
          .post(this.GLOBAL.baseUrl + '/likeAdd', {
            id: this.id,
            uid: 1,
          })
          .then(function(res) {
            let data = res.data;
            // console.log(data)
            if (data.status == 200) {
              // that.commentcount = data.result;
            } else {
              that.$message.error('添加赞后端出错');
            }
          })
          .catch(function(error) {
            that.$message.error('添加赞出错');
          });
      }
    },
    //切换
    detialId(e) {
      this.detailData(e);
      this.cmmentCount(e);
      this.likeCount(e, 1, this.likes);
      this.likeCount(e, 0, this.isLikes);
    },
  },
};
</script>
<style lang="less">
.mdet {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1001;
  overflow: auto;
  background: @bg-color-top;
  .details {
    margin: 0;
    .article {
      background: @bg-color-top;
      border-radius: 0;
      padding: 40px 0 20px;
    }
    .msg {
      display: flex;
      align-self: flex-end;
      color: @font-color-bottom;
      .zan,
      .look {
        padding-right: @padding-sm1;
      }
      .icon {
        padding-right: 2px;
      }
    }
    .detail-top {
      padding: 24px;
      .title {
        font-size: @font-size-bg3;
        font-weight: 700;
        padding-bottom: @padding-sm1;
      }
      .others {
        display: flex;
        justify-content: space-between;
        color: @font-color-bottom;
      }
      .styles {
        color: @font-color-bottom;
        padding-bottom: 10px;
      }
    }
    .introduc {
      padding: @padding-sm 0;
      background: @bg-color-base;
      p {
        width: 100%;
        margin: 0 auto;
        font-size: @font-size-bg1;
        color: @font-color-base;
        line-height: 1.8;
        padding: @padding-sm;
      }
    }
    .content {
      width: 100%;
      overflow: hidden;
      padding: 0 20px;
      p {
        font-size: @font-size-bg1;
        color: @font-color-base;
        line-height: 1.8;
        padding-top: @padding-sm;
        img {
          width: 100%;
          position: relative;
          padding: 4px 0;
        }
      }
    }
  }
  .cont-msg {
    text-align: center;
    margin: 0 auto;
    padding-bottom: @padding-bg;
    background: @bg-color-top;
    .disactive {
      cursor: pointer;
      display: inline-block;
      width: 120px;
      height: 72px;
      border-radius: 36px;
      background: @primary-color;
      transition: all @animation-duration-slow;
      color: #fff;
      .icon {
        font-size: 44px;
        padding-top: 14px;
      }
    }
    .active {
      display: inline-block;
      box-sizing: border-box;
      width: 120px;
      height: 72px;
      border-radius: 36px;
      border: 0px;
      background: @bg-color-base;
      transition: all @animation-duration-slow;
      color: @font-color-base;
      .icon {
        color: @primary-color;
      }
      .icon {
        font-size: 44px;
        padding-top: 14px;
      }
    }
    .names {
      font-size: @font-size-bg3;
      padding-top: @padding-sm;
      padding-bottom: 8px;
      font-weight: 700;
    }
    .msg {
      justify-content: center;
    }
  }
  .detail-bottom {
    width: 100%;
    display: flex;
    flex-direction: row;
    background: @bg-color-top;
    padding: 24px;
    border-radius: 0;
    box-sizing: border-box;
    .comments {
      flex: 1;
      padding: 0;
      background: @bg-color-top;
      margin-right: 0;
      border-radius: @radius-bg;
      transition: all @animation-duration-slow;
    }
  }
  .n-p-bg {
    position: fixed;
    top: 0;
    z-index: 10;
    height: 44px;
    width: 100%;
    background: @bg-color-top;
    .icon {
      color: @font-color-base;
      position: absolute;
      left: 16px;
      top: 12px;
      font-size: 24px;
    }
  }
}
</style>
