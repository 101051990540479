<template>
  <!-- 个人分享 -->
  <div id="produce">
    <div class="article">
      <router-view
        name="mprod"
        ref="child2"
        @detail="toDetail"
      ></router-view>
    </div>
    <Top></Top>
    <!-- <Details></Details> -->
    <Details v-if="isOpen" :id="detailId" @clooseDt="clooseDt()"></Details>
  </div>
</template>

<script>
import { pranddo } from "../../static/js/yike";
import Top from "../../components/top";
import Details from "../../components/mobile/details";
export default {
  name: "Home",
  components: {
    Top,
    Details,
  },
  data: function () {
    return {
      nowclick: 0, //当前菜单
      menus: ["one", "two"], //菜单
      isOpen: false,
      notePhoto: [],
      detailId:'',
      pranddo,
      block: 'block',
    };
  },
  computed: {
   
  },
  created: function () {},
  methods: {
    //文章详情
    toDetail: function(id){
      this.isOpen = true;
      this.detailId = id;
    },
    
    //关闭详情
    clooseDt(){
      this.isOpen= false;
    }
   
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
.article {
padding-top: 72px;
}

</style>

