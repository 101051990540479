<template>
  <div id="tobar">
    <div class="tobar">
      <div class="search">
        <input type="search" class="search-input" v-model="searchData">
        <button @click="search">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconfangdajing"></use>
            </svg>
        </button>
      </div>
      <div class="head-pic">
        <img src="../static/images/headpic.png">
      </div>
    </div>
    <div class="tobar-top" :class="{disps : scroll>120}">
      <div class="search">
        <input type="search" class="search-input" v-model="searchData">
        <button @click="search">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconfangdajing"></use>
            </svg>
        </button>
      </div>
      <div class="head-pic">
        <img src="../static/images/headpic.png">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      scroll: 0,
      abcd: true,
      searchData:'',
    };
  },
  mounted(){
    window.addEventListener('scroll', this.scrollTop)
  },
  methods:{
    search() {
      console.log('不好意思你搜索了个寂寞～');
      this.searchData = '';
    },
    //获取滚动高度
    scrollTop(){
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(this.scroll)
    }
  }
}
</script>
<style scoped lang="less">
.tobar{
  display:flex;
  justify-content:space-between;
  .search{
    padding-bottom: 30px;
    .search-input{
      background: @bg-color-top;
    }
  }
}
#tobar .disps{
  display:block;
}
.tobar-top{
  position: fixed;
  padding: 12px 0 12px 40px;
  top: 0px;
  margin-left: -39px;
  z-index: 100;
  width: 100%;
  background: @bg-color-top;
  border-bottom: 1px solid @bg-color-other;
  display:none;
  transition: all @animation-duration-slow;
  .head-pic{
    position: fixed;
    right: 40px;
    top: 10px;
  }
  .search-input{
      background: @bg-color-base;
      transition: all @animation-duration-slow;
    }
}
  .search{
    .search-input{
      height: 44px;
      width: 456px;
      border-radius: @radius-base;
      padding: 0px 40px 0 12px;
      color: @font-color-top;
      border:0;
      outline:none;
      transition: all @animation-duration-slow;
    }
    button{
      margin-left: -40px;
      height: 44px;
      vertical-align: bottom;
      border:0;
      outline:none;
      background: rgba(0,0,0,0);
      cursor: pointer;
      transition: all @animation-duration-slow;
      &:hover .icon{
        color: @font-color-base;
      }
    }
    .icon{
      width: 20px;
      height: 20px;
      color: @font-color-bottom;
    }
  }
  .head-pic{
    height: 44px;
    width: 44px;
    border-radius: 22px;
    background: @font-color-base;
    overflow: hidden;
    img{
      width: 44px;
    }
  }
</style>