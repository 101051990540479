<template>
  <div id="photo" ref="photo">
    <div :class="carcClass" class="card">
      <div
        class="li-div"
        target="_blank"
        v-for="(rem, index) in articles"
        :key="index"
        @click="detail(rem._id)"
      >
        <div class="img-main">
          <div class="msg">
            <p class="title">{{rem.name}}</p>
            <p class="zan">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#iconIcon-zan1"></use>
              </svg>
              {{rem.likes}}
            </p>
          </div>
          <div class="photo-img">
            <img :src="GLOBAL.baseUrl+'/photography/' + rem.content.split(',')[0]" />
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div v-if="articles.length == 0" class="none">
      <p>还没有内容～</p>
    </div>
    <div class="mores">{{tobottom}}</div>
    <!-- <phdete :id="detailId"></phdete> -->
  </div>
</template>
<script type="text/javascript">
import s from "../static/js/myfunc.js";
import elementR from "element-resize-detector";
import Phdete from "./photoDet";
export default {
  data() {
    return {
      articles: [],
      art:[],
      judge: 3,
      nowpage: 1,
      gjc: -1,
      total: [],
      carcClass: "one",
      tobottom: '',
      isOpen: 0,
      detailId:'616d3aeef5f5922456665004',
    };
  },
  components: {
    Phdete
  },
  props: {
    display: {
      // 每页显示条数
      type: Number,
      default: 10,
    },
  },
  computed: {},
  created: function () {
    this.datas(this.nowpage);
    this.totals();
  },
  methods: {
    datas: function (nowpg) {
      //数据项
      // this.articles = [];
      this.CommonJs.content(
        this,
        nowpg,
        this.display,
        this.judge,
        this.gjc,
        this.articles
      );
    },
    totals: function () {
      this.CommonJs.counts(this, this.gjc, this.judge, this.total);
    },
    //点击进入详情
    detail: function(id){
      let _this = this;
      _this.$axios
        .post(_this.GLOBAL.baseUrl + "/timesAdd", {
          id: id,
        })
        .then(function (res) {
          var data = res.data;
          if (data.status == 200) {
            
          } else {
            console.log("访问详情后端出错");
          }
        })
        .catch(function (error) {
          // console.log(error);
          console.log("访问详情数出错");
        });
      _this.$emit('detail',id)
    },
    //监听元素宽度变化
    watchSize() {
      const _this = this;
      const erd = elementR();
      erd.listenTo(this.$refs.photo, (element) => {
        var width = element.offsetWidth;
        var height = element.offsetHeight;
        _this.$nextTick(() => {
          // 这里填写监听改变后的操作
          if (width > 400) {
            this.carcClass = "one";
          } else {
            this.carcClass = "two";
          }
        });
      });
    },
    //加载更多
    morepage: function(){
      var scrolltop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if(scrolltop + window.innerHeight >= document.body.offsetHeight-100) {
        if(this.nowpage<Math.ceil(this.total[0]/this.display+1)){
          this.tobottom = "";
          this.nowpage++;
          this.datas(this.nowpage);
        }else{
          this.tobottom = "已到底部...";
        }
          
      }
    },
  },
  mounted() {
    this.watchSize();s.addEvent(window,'scroll',this.morepage);
  },
};
</script>
<style lang="less" scoped>
#photo {
  width: 100%;
  padding-bottom: @padding-base;
}
.card {
  // 瀑布流样式
  // width: 500px;
  // height: 600px;;
  overflow: hidden;
  // overflow-y: scroll;
  margin: 0;
  // column-count: 3;
  // -webkit-column-count: 3;
  // -moz-column-count: 3;
  // -moz-column-gap: 20px;
  // -webkit-column-gap: 20px;
  // column-gap: 20px;
  margin: auto;
  .li-div {
    display: block;
    text-decoration: none;
    overflow: hidden;
  }
  .img-main {
    position: relative;
    background: rgba(0,0,0,0.4);
    img {
      width: 100%;
    }
    .msg {
      position: absolute;
      bottom:-2px;
      opacity: 0;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content:space-between;
      color: #fff;
      background-image: linear-gradient(rgba(0,0,0,0), rgba(0,0,0,0.5));
      transition: all @animation-duration-slow;
      .title{
        font-weight: 700;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
      }
      .zan{
        display:flex;
        padding-left: @padding-sm1;
        .icon{
          padding: 2px;
        }
      }
    }
    &:hover .msg{
      opacity: 1;
    }
  }
}
.one {
  column-count: 3;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  -moz-column-gap: 20px;
  -webkit-column-gap: 20px;
  column-gap: 20px;
  margin: auto;
  .li-div {
    margin-bottom: @padding-sm;
    border-radius: @radius-base;
  }
  .img-main {
    .msg {
      padding: @padding-sm1;
      .title{
        font-size: @font-size-bg1;
      }
    }
  }
}
.two {
  column-count: 2;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -moz-column-gap: @padding-sm1;
  -webkit-column-gap: @padding-sm1;
  column-gap: @padding-sm1;
  margin: auto;
  .li-div {
    margin-bottom: @padding-sm1;
    border-radius: @radius-sm;
  }
  .img-main {
    .msg {
      padding: 12px;
      .title{
        font-size: @font-size-base;
      }
    }
  }
}
.mores{
  text-align: center;
  padding-top: @padding-base;
  color: @font-color-bottom;
}
</style>