<template>
  <!-- 个人分享 -->
  <div id="share">
    <div class="nav">
      <div
        class="nav-category"
        v-for="(item, index) in category"
        :key="index"
        :class="{ selected: index == nowclick }"
        @click="catSelect(index)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="article" :class="menus[0]">
      <div v-if="menus[0] != 'one'" class="title">文章</div>
      <router-view
        name="Article"
        ref="child2"
        @detail="toDetail"
      ></router-view>
    </div>
    <div class="photog" :class="menus[1]">
      <div v-if="menus[1] != 'one'" class="title">摄影</div>
      <router-view name="Photog" @detail="toPhDetail"></router-view>
    </div>
    <div class="note" :class="menus[2]">
      <div v-if="menus[2] != 'one'" class="title">随记</div>
      <router-view name="Notes" @notePhoto="note"></router-view>
    </div>
    <Top></Top>
    <PhDetails v-if="isOpen == -3" :id="detailId" @clooseDt="clooseDt()"></PhDetails>
    <Details v-if="isOpen == -1" :id="detailId" :judge=5 @clooseDt="clooseDt()"></Details>
    <bigPic v-if="isOpen == -2" :picarr="notePhoto" :nowpic="nowpic" @clooseDt="clooseDt()"></bigPic>
  </div>
</template>

<script>
import { share } from "../static/js/yike";
import Top from "../components/top";
import Details from "../components/details";
import PhDetails from "../components/photoDet";
import bigPic from "../components/bigpicture";
export default {
  name: "Home",
  components: {
    Top,
    Details,
    PhDetails,
    bigPic,
  },
  data: function () {
    return {
      nowclick: 0, //当前菜单
      menus: ["one", "two", "three"], //菜单
      isOpen: 0,
      notePhoto: [],
      detailId:'',
      nowpic:0,
    };
  },
  computed: {
    category() {
      let cat = share;
      return cat;
    },
    products() {
      let prd = product;
      return prd;
    },
  },
  created: function () {
    
  },
  methods: {
    //卡片切换
    catSelect(e) {
      this.nowclick = e;
      if (e == 0) {
        this.menus[0] = "one";
        this.menus[1] = "two";
        this.menus[2] = "three";
      } else if (e == 1) {
        this.menus[0] = "two";
        this.menus[1] = "one";
        this.menus[2] = "three";
      } else {
        this.menus[0] = "two";
        this.menus[1] = "three";
        this.menus[2] = "one";
      }
    },
    //文章详情
    toDetail: function(id){
      this.isOpen = -1;
      this.detailId = id;
    },
    //摄影详情
    toPhDetail: function(id){
      this.isOpen = -3;
      this.detailId = id;
    },
    //日志数据展示
    note: function (arr, j) {
      this.isOpen = -2;
      this.nowpic = j;
      this.notePhoto = [];
      for (let i = 0; i < arr.imgs.length; i++) {
        this.notePhoto[i] = this.GLOBAL.baseUrl + "/diary/" + arr.imgs[i];
      }
      // console.log(this.notePhoto);
    },
    //关闭详情
    clooseDt(){
      this.isOpen = 0;
    },
  },
};
</script>

<style lang="less" scoped>
#share {
  min-height: 1160px;
  position: relative;
}
.nav {
  display: flex;
  margin-top: -12px;
  .nav-category {
    margin-right: @padding-bg;
    color: @font-color-base;
    line-height: 2;
    transition: all @animation-duration-slow;
    cursor: pointer;
  }
  .selected {
    font-weight: 700;
    color: @font-color-top;
  }
}
.awrticle {
  width: 100%;
  height: 100%;
  border: 1px solid red;
}
.one {
  // width: 100%;
  margin-right: 420px;
  margin-top: 12px;
  border-radius: @radius-bg;
  // background: @bg-color-top;
  transition: all @animation-duration-slow;
}
.two,
.three {
  position: absolute;
  right: 0;
  width: 390px;
  height: 560px;
  padding: 30px 30px 0;
  box-sizing: border-box;
  border-radius: @radius-bg;
  background: @bg-color-top;
  transition: all @animation-duration-slow;
  overflow: auto;
  .title {
    font-size: @font-size-bg2;
    font-weight: 700;
    padding-bottom: @padding-sm;
  }
}
.two {
  top: 40px;
}
.three {
  top: 600px + @padding-base;
}
.two::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 0px;
}
.two::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 4px;
  background: @font-color-bottom;
}
.two::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 4px;
  background: @bg-color-bottom;
}
.three::-webkit-scrollbar {
  /*滚动条整体样式*/
  width: 0px; /*高宽分别对应横竖滚动条的尺寸*/
  height: 0px;
}
.three::-webkit-scrollbar-thumb {
  /*滚动条里面小方块*/
  border-radius: 4px;
  background: @font-color-bottom;
}
.three::-webkit-scrollbar-track {
  /*滚动条里面轨道*/
  border-radius: 4px;
  background: @bg-color-bottom;
}
.displays {
  display: none;
}

</style>

