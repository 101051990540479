/**
* 自定义主题方案
**/
export const menusModel = {
    themeA: {
      bgColorTop:'#fff',
      bgColorBase:'#F4F4F5',
      bgColorBottom:'#DDDFE4',
      bgColorOther:'#F4F4F5',
      fontColorTop:' #1F1D2B',
      fontColorBase:'#3B3948',
      fontColorBottom:'#9195A1',
    },
    themeB: {
      bgColorTop:'#202025',
      bgColorBase:'#17161B',
      bgColorBottom:'#2E2E35',
      bgColorOther:'#2E2E35',
      fontColorTop:' #fff',
      fontColorBase:'#DDDFE4',
      fontColorBottom:'#9195A1',
    },
    
  }