<template>
  <div id="mtobar">
    <div class="tobar">
      <svg class="caidan" aria-hidden="true" @click="caidans">
        <use xlink:href="#iconcaidan"></use>
      </svg>
      <div class="search">
        <input type="search" class="search-input" v-model="searchData">
        <button @click="search">
          <svg class="icon" aria-hidden="true">
            <use xlink:href="#iconfangdajing"></use>
          </svg>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      scroll: 0,
      abcd: true,
      searchData: '',
    };
  },
  mounted() {
    window.addEventListener('scroll', this.scrollTop)
  },
  methods: {
    search() {
      this.$message.info('不好意思你搜索了个寂寞～');
      this.searchData = '';
    },
    //获取滚动高度
    scrollTop() {
      this.scroll = document.documentElement.scrollTop || document.body.scrollTop;
      // console.log(this.scroll)
    },
    //调取菜单
    caidans() {
      this.$emit("cddq");
    }
  }
}
</script>
<style scoped lang="less">
#mtobar {
  .tobar {
    background: @bg-color-base;
    display: flex;
    justify-content: space-between;
    padding: 4px 24px;
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    box-sizing: border-box;

    .caidan {
      margin-top: 14px;
      margin-right: 12px;
      width: 20px;
      height: 18px;
      flex-grow: 0;
      fill: @font-color-top;
    }

    .search {
      .search-input {
        background: @bg-color-top;
      }
    }
  }

  .search {
    flex-grow: 2;

    .search-input {
      height: 44px;
      width: 100%;
      border-radius: @radius-base;
      padding: 0px 40px 0 12px;
      color: @font-color-top;
      border: 0;
      outline: none;
      transition: all @animation-duration-slow;
    }

    button {
      position: absolute;
      right: 26px;
      height: 44px;
      width: 44px;
      vertical-align: bottom;
      border: 0;
      outline: none;
      background: rgba(0, 0, 0, 0);
      cursor: pointer;
      transition: all @animation-duration-slow;

      &:hover .icon {
        color: @font-color-base;
      }
    }

    .icon {
      margin-top: 4px;
      width: 20px;
      height: 20px;
      color: @font-color-bottom;
    }
  }

  .head-pic {
    height: 44px;
    width: 44px;
    border-radius: 22px;
    background: @font-color-base;
    overflow: hidden;

    img {
      width: 44px;
    }
  }
}
</style>