<template>
  <!-- 首页 -->
  <div class="mhome">
    <div class="car">
      <div class="a-carousel">
        <div class="card-m" v-show="nowcard == 0">
          <div class="card-left">
            <p class="card-title">
              vue+node.js <br />
              0到1实现即时通讯聊天室
            </p>
            <p class="introduce">
              单聊、群聊 <br />
              可发送文字、表情、图片、语音、位置...
            </p>
          </div>
          <div class="links">
            <div class="firstbt downapp" @click="tepDow(-1)">下载应用</div>
            <div class="design" @click="tepDes(0)">设计稿</div>
            <a href="https://space.bilibili.com/485469670" target="_blank" class="bzhan">b站</a>
          </div>
          <div class="card-right">
            <img src="../../static/images/yklt.png" />
          </div>
        </div>
        <div class="card-m" v-show="nowcard == 1">
          <div class="card-left">
            <p class="card-title">
              留言墙 <br />
              等你来留言
            </p>
            <p class="introduce">
              很多事情值得记录，当然也值得回味。
            </p>
          </div>
          <div class="links">
            <div class="firstbt liuyan" @click="goLyq">去留言～</div>
            <div class="design" @click="tepDes(1)">设计稿</div>
            <a href="https://space.bilibili.com/485469670" target="_blank" class="bzhan">b站</a>
          </div>
          <div class="card-right">
            <img src="../../static/images/lyq.png" />
          </div>
        </div>

      </div>
    </div>
    <div class="datas">
      <div class="cards">
        <div class="datas-li card" v-for="(item, index) in module" :key="index">
          <div class="li-inner">
            <div class="top">
              <div class="icons" :style="{ background: item.color }">
                <svg class="icon" aria-hidden="true">
                  <use :xlink:href="item.icon"></use>
                </svg>
              </div>
              <div class="data" :style="{ color: item.color }">
                {{ item.data }}
              </div>
            </div>
            <p class="data-title">{{ item.title }}</p>
            <p class="inform">{{ item.explain }}</p>
            <div class="data-button" @click="goAbout(item.src)">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#iconIcon-jinru"></use>
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="datas-li toto">
        <p class="visit">{{ 10200 + viseToto[0] }}</p>
        <p class="visit-title">总访问量</p>
        <p class="visit">{{ todayToto }}</p>
        <p class="visit-title">今日访问量</p>
        <div class="outlinks">
          <div class="outlink-inner wx" @click="wxma">
            <img class="wxgzh-img" src="../../static/images/wxgzh.png" :style="{ width: wxclick }">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconweixin"></use>
            </svg>
          </div>
          <a class="outlink-inner bz" href="https://space.bilibili.com/485469670" target="_blank">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconIcon-Bzhan"></use>
            </svg>
          </a>
          <a class="outlink-inner zk" @click="getData">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconIcon-zhanku"></use>
            </svg>
          </a>
        </div>
      </div>
    </div>
    <reward v-if="isopen" @clooseDt="clooseDt()" @goto="rewardBack"></reward>
  </div>
</template>
<script>
import { module } from "../../static/js/yike";
import reward from "../../components/mobile/reward.vue";
//  import { message } from 'ant-design-vue';
export default {
  data: function () {
    return {
      module,
      viseToto: [],
      todayToto: 0,
      nav: [],
      isopen: false,
      ref: 0, //谁触发的打赏
      wxclick: '0px',
      nowcard:0,
    };
  },
  components: {
    reward,
  },
  computed: {},
  created: function () {
    this.CommonJs.countVisit(this, 1, this.viseToto);
    this.todayVisit();
    this.sessionTest();
    this.mageCount();
    this.changeCard();
  },
  methods: {
    //切换
    changeCard() {
      let timer = setInterval(() => {
        this.nowcard++;
        if (this.nowcard > 1) {
          this.nowcard = 0;

        }
      }, 3000);
    },
    //关闭详情
    clooseDt() {
      this.isopen = false;
    },
    //设计稿打赏按钮
    tepDes(e) {
      this.ref = e;
      this.isopen = true;
    },
    //下载打赏按钮
    tepDow(e) {
      this.ref = e;
      this.isopen = true;
    },
    //去留言墙
    goLyq() {
      // this.$message.info("功能开发中～");
      window.open("https://www.huohuo90.com:3002/", '_blank');
    },
    //判断是谁的返回
    rewardBack() {
      if (this.ref == -1) {
        this.downApp();
      } else {
        this.yikeDesign(this.ref);
      }
      this.isopen = false;
      this.ref = 0;
    },
    //点击微信头像
    wxma() {
      if (this.wxclick == '0px') {
        this.wxclick = '120px';
      } else {
        this.wxclick = '0px';
      }
    },
    //跳转设计稿
    yikeDesign(e) {
      // this.$router.push('/yikes');
      if (e == 0) {
        this.CommonJs.getVisit(this, 2);
        window.location.href = this.GLOBAL.baseUrl + "/yikes";
      } else if (e == 1) {
        this.CommonJs.getVisit(this, 3);
        window.location.href = this.GLOBAL.baseUrl + "/lyq";
      }
    },
    //下载应用
    downApp() {
      this.CommonJs.getVisit(this, 0);
      window.location.href = this.GLOBAL.baseUrl + "/download";
    },
    info() {
      this.$message.info("不好意思你点了个寂寞～");
    },
    //获取点击数字
    todayVisit() {
      this.$axios({
        url: this.GLOBAL.baseUrl + "/todayvisit",
        method: "POST",
        data: { types: 1 },
      })
        .then((response) => {
          let rest = response.data; //这里使用了ES6的语法
          if (rest.success) {
            this.todayToto = rest.rest;
          }
        })
        .catch((error) => {
          console.log(error); //请求失败返回的数据
        });
    },

    //获取付出数据
    mageCount: function () {
      var _this = this;
      _this.$axios
        .post(_this.GLOBAL.baseUrl + "/mageCount", {})
        .then(function (res) {
          var data = res.data;
          if (data.status == 200) {
            _this.module[0].data = _this.module[1].data = 0;
            for (let i = 0; i < data.ress.length; i++) {
              if (data.ress[i].types == 0 || data.ress[i].types == 2) {
                _this.module[0].data++;
              } else {
                _this.module[1].data++;
              }
            }
          } else if (data.status == 300) {
            _this.$message({
              message: "验证信息有误，请重新登录！",
              type: "warning",
            });
            _this.$router.push({ path: "/mlogin" });
          } else if (data.status == 500) {
            _this.$message.error(data.result);
            _this.$router.push({ path: "/mlogin" });
          }
        });
    },
    //获取付出数据
    noteCount: function () {
      var _this = this;
      _this.$axios
        .post(_this.GLOBAL.baseUrl + "/noteCount", {
          token: _this.user.token,
        })
        .then(function (res) {
          var data = res.data;
          if (data.status == 200) {
            let arr = _this.navs[2];
            arr.num = data.result.ress;
            _this.navs.splice(2, 1, arr);
          } else if (data.status == 300) {
            _this.$message({
              message: "验证信息有误，请重新登录！",
              type: "warning",
            });
            _this.$router.push({ path: "/mlogin" });
          } else if (data.status == 500) {
            _this.$message.error(data.result);
            _this.$router.push({ path: "/mlogin" });
          }
        });
    },

    //session测试
    sessionTest() {
      if (!window.sessionStorage.getItem("key")) {
        window.sessionStorage.setItem("key", "loading");
        console.log("第一次");
        this.CommonJs.getVisit(this, 1);
      }
    },
    //获取数据
    getData() {
      this.CommonJs.countVisit(this, 0, this.viseToto);
      this.CommonJs.countVisit(this, 2, this.viseToto);
      this.CommonJs.countVisit(this, 3, this.viseToto);
      console.log(this.viseToto)
      this.info();
    },
    //获取点击记录详情
    visitDetial() {
      this.$axios({
        url: this.GLOBAL.baseUrl + "/visitdetial",
        method: "POST",
        data: "",
      })
        .then((response) => {
          let rest = response.data; //这里使用了ES6的语法
          if (rest.success) {
            console.log(rest.rest);
          }
        })
        .catch((error) => {
          console.log(error); //请求失败返回的数据
        });
    },
    //进入相应模块
    //切换菜单
    goAbout(path) {
      this.CommonJs.goTop();
      this.$router.push(path);
    },
  },
};
</script>
<style lang="less">
/* For demo */
.mhome {
  padding-top: 76px;

  .car {
    padding: 0 24px 24px;
    box-sizing: border-box;
    // height: 314px;
  }

  .card-m {
    position: relative;
    height: 256px;
    width: 100%;
    background-color: @bg-color-top;
    border-radius: @radius-bg;
    overflow: hidden;

    .card-left {
      position: relative;
      z-index: 10;
      height: 100%;
      padding: @padding-base;

      .card-title {
        font-size: 21px;
        font-weight: 700;
        color: @font-color-top;
        transition: all @animation-duration-slow;
      }

      .introduce {
        padding-top: @padding-sm1;
        font-size: @font-size-base;
        color: @font-color-base;
        line-height: 1.8;
        transition: all @animation-duration-slow;
      }

      .supple {
        padding-top: @padding-bg;
        max-width: 480px;
        color: @font-color-bottom;
        line-height: 2;
        transition: all @animation-duration-slow;
      }
    }

    .links {
      display: flex;
      z-index: 10;
      position: absolute;
      bottom: @padding-base;
      left: @padding-base;

      .firstbt {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 24px;
        border-radius: 24px;
        width: 108px;
        height: 48px;
        color: @font-color-top;
        font-weight: 700;
        cursor: pointer;
        margin-right: @padding-sm;

        &:hover {
          border-color: @font-color-top;
        }
      }

      .design {
        display: flex;
        justify-content: space-around;
        align-items: center;
        background: @primary-color;
        border-radius: 24px;
        border-radius: 24px;
        height: 48px;
        width: 80px;
        color: #fff;
        font-weight: 700;
        cursor: pointer;
        margin-right: @padding-sm;
        padding: 0 10px;
        transition: all @animation-duration-slow;
      }

      .downapp {
        background: @bg-color-top;
        border: 1px solid @font-color-base;
      }

      .liuyan {
        background-image: linear-gradient(90deg, #F3CCFF 1%, #C1EEFF 100%);
      }

      .bzhan {
        line-height: 48px;
        text-decoration: none;
        color: @primary-color;
        font-weight: 700;

        &:hover {
          // color: @primary-5;
        }
      }
    }

    .card-right {
      position: absolute;
      left: 0;
      top: 0;
      z-index: 1;
      opacity: 0.2;

      img {
        width: 100%;
      }
    }
  }

  .datas {
    .title {
      font-size: @font-size-bg3;
      color: @font-color-top;
      font-weight: 700;
      padding-bottom: @padding-base;
      transition: all @animation-duration-slow;
    }

    .datas-li {
      .visit {
        font-size: 72px;
        font-weight: 600;
        line-height: 1.4;
        color: @font-color-top;
        transition: all @animation-duration-slow;
      }

      .visit-title {
        color: @font-color-base;
        padding-bottom: @padding-sm;
        transition: all @animation-duration-slow;
      }

      .li-inner {
        position: relative;
        box-sizing: border-box;
        height: 100%;
        width: 256px;
        border-radius: @radius-bg;
        background: @bg-color-top;
        padding: @padding-base;
        transition: all @animation-duration-slow;

        .top {
          display: flex;
          justify-content: space-between;

          .icons {
            // background: #22BF87;
            border-radius: @radius-sm;
            width: 56px;
            height: 56px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .icon {
              font-size: 30px;
              color: #fff;
            }
          }

          .data {
            font-size: 36px;
            // color: #22BF87;
          }
        }

        .data-title {
          font-size: @font-size-bg2;
          font-weight: 700;
          color: @font-color-top;
          padding-top: @padding-sm;
          transition: all @animation-duration-slow;
        }

        .inform {
          padding-top: @padding-sm1;
          color: @font-color-bottom;
          transition: all @animation-duration-slow;
        }
      }
    }

    .toto {
      padding: 36px 0 36px 24px;
      position: relative;
    }

    .cards {
      height: 300px;
      padding-right: 24px;
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      flex-direction: row;
    }

    .card {
      margin-left: 24px;
      flex-grow: 0;
      width: 252px;
      height: 300px;
    }

    .data-button {
      width: 80px;
      height: 40px;
      background: @primary-color;
      border-radius: 20px;
      position: absolute;
      right: @padding-base;
      bottom: @padding-base;
      display: flex;
      justify-content: space-around;
      align-items: center;
      color: #fff;
      font-size: 24px;
      cursor: pointer;
    }

    .outlinks {
      display: flex;

      .outlink-inner {
        width: 44px;
        height: 44px;
        border-radius: @radius-base;
        margin-right: 16px;
        display: flex;
        justify-content: space-around;
        align-items: center;
        transition: all @animation-duration-slow;

        .icon {
          font-size: 20px;
        }
      }

      .wx {
        cursor: pointer;
        position: relative;

        .wxgzh-img {
          position: absolute;
          bottom: 40px;
          left: 0px;
          width: 0px;
          transition: all @animation-duration-slow;
        }

        background: #22bf58;

        .icon {
          color: #fff;
        }
      }

      .bz {
        background: #ff509b;

        .icon {
          color: #fff;
        }
      }

      .zk {
        background: #ffe300;

        .icon {
          color: #222;
        }
      }
    }
  }
}
</style>