<template>
  <!-- 个人分享 -->
  <div id="produce">
    <div class="article">
      <router-view
        name="Prod"
        ref="child2"
        @detail="toDetail"
      ></router-view>
    </div>
    <Top></Top>
    <!-- <Details></Details> -->
    <Details v-if="isOpen" :id="detailId" :judge=6  @clooseDt="clooseDt()"></Details>
  </div>
</template>

<script>
import { pranddo } from "../static/js/yike";
import Top from "../components/top";
import Details from "../components/details";
export default {

  components: {
    Top,
    Details,
  },
  data: function () {
    return {
      nowclick: 0, //当前菜单
      menus: ["one", "two"], //菜单
      isOpen: false,
      notePhoto: [],
      detailId:'',
      pranddo,
      block: 'block',
    };
  },
  computed: {
   
  },
  created: function () {},
  methods: {
    //文章详情
    toDetail: function(id){
      this.isOpen = true;
      this.detailId = id;
    },
    
    //关闭详情
    clooseDt(){
      this.isOpen= false;
    }
   
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#produce {
  min-height: 1200px;
  position: relative;
.awrticle {
  width: 100%;
  height: 100%;
  border: 1px solid red;
}
.one {
  width: 100%;
  margin-top: 12px;
  border-radius: @radius-bg;
  // background: @bg-color-top;
  transition: all @animation-duration-slow;
}
.displays {
  display: none;
}
.notes-photo {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1001;
  overflow: auto;
  .n-p-bg {
    position: fixed;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.9);
    .icon {
      color: #fff;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: @font-size-bg2;
    }
  }
  .n-p-m {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    margin: auto;
    max-height: 96%;
    max-width: 88%;
  }
  
}
}

</style>

