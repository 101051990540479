<template>
  <div class="mcomment">
    <div class="comment-input">
      <textarea
          class="text inputs"
          placeholder="对内容有什么看法？"
          v-model="comment"
        ></textarea>
        <div class="input-bt">
          <button
            class="submit"
            :class="submitOk ? 'ok' : 'no'"
            @click="submit"
          >
            评论
          </button>
        </div>
    </div>
    <div class="comment-title">评论</div>
    <div class="comment-list" v-for="(item, index) in commentList" :key="index">
      <div class="user-header">
        <img :src="item.icon" />
      </div>
      <div class="input-main">
        <div class="name-time">
          <span class="name">{{ item.name }}</span
          ><span class="tip"></span><span class="time">{{ item.time }}</span>
        </div>
        <p class="comment-main">
          {{ item.comment }}
        </p>
      </div>
    </div>
    <p class="mores istrue" v-if="!isbottom" @click="morepage">
      点击加载更多评论
    </p>
    <p class="mores isfalse" v-if="isbottom">没有更多评论</p>
  </div>
</template>
<script>
import s from "../../static/js/myfunc.js";
export default {
  data: function () {
    return {
      comment: "", //评论内容
      num: 1, //当前页面
      display: 6, //单页条数
      isbottom: false, //是否加载结束
      commentList: [], //评论内容
    };
  },
  props: {
    id: {
      // 文章id
      type: String,
      default: "0",
    },
    count: {
      // 评论数
      type: Number,
      default: "0",
    },
  },
  computed: {
    submitOk: function () {
      if (this.comment.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  created: function () {
    this.getComment();
  },
  methods: {
    //分页获取评论
    getComment: function () {
      var _this = this;
      _this.$axios
        .post(_this.GLOBAL.baseUrl + "/comment", {
          id: _this.id,
          nowpage: _this.num,
          display: _this.display,
        })
        .then(function (res) {
          var data = res.data;
          if (data.status == 200) {
            _this.num++;
            for (let i = 0; i < data.result.length; i++) {
              data.result[i].time = s.detiaTime(data.result[i].time);
              data.result[i].icon =
                _this.GLOBAL.baseUrl + "/avatar/" + data.result[i].icon;
              _this.commentList.push(data.result[i]);
              // console.log(_this.commentList);
            }
          } else {
            _this.$message.error("访问评论后端出错");
          }
        })
        .catch(function (error) {
          // console.log(error);
          _this.$message.error("访问评论出错");
        });
    },
    //加载更多
    morepage: function () {
      console.log("a");
      if ((this.num - 1) * this.display < this.count) {
        this.getComment();
      } else {
        this.isbottom = true;
      }
    },
    //上传评论
    //很高兴，再一次遇到你，今天能看到你实属不易。为什么呢？因为时隔这么久。很多东西都淡忘了。
    submit: function () {
      if (this.comment.length > 0) {
        let _this = this,
          cname = "匿名";
        let data = {
          comment: _this.comment,
          name: cname,
          pic: '00.png',
          icon: _this.GLOBAL.baseUrl + '/avatar/00.png' ,
          id: _this.id,
          uid: 1,
          time: s.detiaTime(new Date()),
        };
        _this.comment = "";
        _this.commentList.unshift(data);
        _this.$axios
          .post(_this.GLOBAL.baseUrl + "/upcomment", data)
          .then(function (res) {
            var data = res.data;
            if (data.status == 200) {
              //alert('评论成功！')
              _this.$emit("commented");
            } else {
              _this.$message.error("添加评论后端出错");
            }
          })
          .catch(function (error) {
            _this.$message.error("添加评论出错");
          });
      }
    },
  },
  watch: {
    //限制输入长度
    // comment() {
    //   if (this.comment.length > 320) {
    //     this.comment = String(this.comment).slice(0, 320);
    //   }
    // },
  },
};
</script>
<style lang="less" scoped>
.mcomment {
  .user-header {
    margin-right: @padding-sm;
    img {
      width: 100%;
      width: 48px;
      height: 48px;
      border-radius: 50%;
      overflow: hidden;
      background: @bg-color-bottom;
    }
    .change-head {
      padding-top: 8px;
      text-align: center;
      color: @font-color-bottom;
    }
  }
  .input-main {
    flex: 1;
    // background: @bg-color-bottom;
  }
  .comment-input {
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 100;
    background: @bg-color-top;
    width: 100%;
    display: flex;
    padding: 8px 24px;
    box-sizing: border-box;
    .inputs {
      box-sizing: border-box;
      border-radius: @radius-sm;
      background: @bg-color-other;
      border: 0;
      outline: none;
      padding: 9px;
      resize: none;
      color: @font-color-base;
      font-size: @font-size-base;
      border: 1px solid @bg-color-other;
      transition: all @animation-duration-slow;
      &:focus {
        border: 1px solid @font-color-top;
        background: @bg-color-top;
      }
    }
    .text {
      width: 100%;
      height: 42px;
      &:focus {
        border: 1px solid @font-color-top;
        background: @bg-color-top;
      }
    }
    .input-bt {
        margin-left: 12px;
      .submit {
        width: 60px;
        height: 42px;
        border-radius: 8px;
        border: 0;
        outline: none;
        background: @primary-color;
        transition: all @animation-duration-slow;
        color: #fff;
      }
      .ok {
        cursor: pointer;
        background: @primary-color;
      }
      .no {
        cursor: not-allowed;
        background: @font-color-bottom;
        &:hover {
          background: @font-color-bottom;
        }
      }
    }
  }
  .comment-title{
      font-size: 18px;
      font-weight: 700;
      color: @font-color-top;
      padding-bottom: 24px;
  }
  .comment-list {
    display: flex;
    padding-bottom: @padding-bg;
    .name-time {
      padding-bottom: 4px;
      .name {
        font-weight: 700;
      }
      .tip {
        display: inline-block;
        vertical-align: middle;
        margin: 6px;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background: @font-color-bottom;
      }
      .time {
        font-size: @font-size-sm;
        color: @font-color-bottom;
      }
    }
    .comment-main {
      color: @font-color-base;
    }
  }
  .mores {
    text-align: center;
    color: @font-color-bottom;
    margin-bottom: 60px;
  }
  .istrue {
    cursor: pointer;
    background: @bg-color-base;
    line-height: 36px;
    border-radius: 18px;
  }
}
</style>