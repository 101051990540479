<template>
   <div :class="{tops:aa,showtop:tops}" @click="goTop">
    	<p>top</p>
    </div>
</template>
<script type="text/javascript">
import s from '../static/js/myfunc.js'
	export default {
	data () {
		return {
			tops: false,
			aa: true,
		}
	},
     computed:{
     	
	},
	methods:{
	    aaa: function(){
			var _this = this;
    		if(window.pageYOffset >500) {
                this.tops = true;
                // console.log('toptoto')
        	}else{
        		this.tops = false;
        	}
		},
		toTop: function(){
	        var sTop =document.documentElement;
	        sTop.scrollTop = 0;
		},
		goTop: function() {	
		this.CommonJs.goTop();
      },
	},
	mounted:function(){
		s.addEvent(window,'scroll',this.aaa);
	},
};
</script>
<style lang="less">
.tops{
	width: 48px;
	height: 48px;
	background: @font-color-base;
    opacity: 0.3;
	position: fixed;
	bottom: -60px;
    right: 40px;
	transition: all @animation-duration-slow;
    border-radius: @radius-base;
	cursor:pointer;
	z-index: 1000;
    text-align: center;
	p{
		line-height: 48px;
		color:@bg-color-base;
	}
	&:hover{
		opacity: 0.6;
	}
}
.showtop{
	bottom: 80px !important; 
}
</style>