<template>
  <div class="reward">
    <!-- 打赏页面 -->
    <div class="dashang">
      <svg class="icon" aria-hidden="true" @click="clooser()">
        <use xlink:href="#iconguanbi1"></use>
      </svg>
      <p class="ds-header">休息一下</p>
      <p class="ds-js">看在这个作者分享不易上，请休息一下吧🥺…</p>
      <div class="ds-img">
        <img src="../static/images/wx1.png" />
        <img src="../static/images/zfb1.png" />
      </div>
      <div class="ds-btn">
        <p class="ds-btn-no" @click="goto" :class="{ not: !isok }">
          <span v-show="t > 0">{{ t }}s后</span>直接跳过
        </p>
        <p class="ds-btn-yes" @click="reward">支持 {{ dscs[0] }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      dscs: [],
      isok: false,
      t: 9,
    };
  },
  props: {
    id: {
      // 文章id
      type: String,
      default: '0',
    },
    judge: {
      // 文章id
      type: Number,
      default: 5,
    },
  },
  created: function() {
    this.getds();
  },
  watch: {
    id: {
      handler() {
        this.setTime();
      },
      // 这里增加了一个immediate属性，说明监听到props传参后立即先去执行handler方法
      immediate: true,
    },
  },

  methods: {
    //关闭窗口
    clooser() {
      this.$emit('clooseDt');
    },
    //出发按钮
    goto() {
      if (this.isok) {
        this.$emit('goto');
      }
    },
    //点击大赏
    reward() {
      if (this.t < 6) {
        this.CommonJs.getVisit(this, 3);
        console.log('感谢您的支持～');
        this.$emit('goto');
      }
    },
    //获取大赏次数
    getds() {
      this.CommonJs.countVisit(this, 3, this.dscs);
    },
    //倒计时
    setTime() {
      this.t = 9;

      let timer = setInterval(() => {
        this.t--;
        if (this.t <= 0) {
          clearInterval(timer);
          this.isok = true;
        }
      }, 1000);
    },
  },
  mounted() {},
};
</script>
<style scoped lang="less">
.reward {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1001;
  background: rgba(0, 0, 0, 0.4);

  .header {
    text-align: center;

    p {
      line-height: 80px;
      font-size: 24px;
      color: @font-color-bottom;
    }
  }

  .dashang {
    width: 600px;
    height: 480px;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    text-align: center;
    border-radius: @radius-bg;
    background: @bg-color-top;

    .icon {
      font-size: 28px;
      color: @font-color-bottom;
      position: absolute;
      right: 12px;
      top: 12px;
      cursor: pointer;
    }

    .ds-header {
      font-size: 24px;
      color: @font-color-top;
      padding-top: 24px;
      font-weight: 700;
    }

    .ds-js {
      color: @font-color-bottom;
      padding-top: 10px;
      padding-bottom: 20px;
    }

    .ds-img {
      display: flex;
      justify-content: center;

      img {
        width: 200px;
        margin: 15px;
      }
    }

    .ds-btn {
      padding-top: 10px;
      display: flex;
      justify-content: center;
      cursor: pointer;

      .not {
        cursor: not-allowed;
      }

      p {
        height: 48px;
        border-radius: 24px;
        padding: 0 24px;
        line-height: 48px;
        margin: 12px;
        font-weight: 700;
      }

      .ds-btn-no {
        border: 1px solid @font-color-bottom;
        color: @font-color-bottom;
      }

      .ds-btn-yes {
        color: #fff;
        background: @primary-color;
      }
    }
  }
}
</style>
