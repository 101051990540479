<template>
  <!-- 个人分享 -->
  <div id="resource">
    <div class="article">
      <resour
        name="Prod"
        ref="child2"
        @detail="toDetail"
      ></resour>
    </div>
    <Top></Top>
    <reward v-if="isopen" @clooseDt="clooseDt()" @goto="download" :id="href"></reward>
  </div>
</template>

<script>
import { pranddo } from "../static/js/yike";
import Top from "../components/top";
import Details from "../components/details";
import resour from "../components/resources.vue"
import reward from "../components/reward.vue";
export default {
  name: "Home",
  components: {
    Top,
    Details,
    resour,
    reward,
  },
  data: function () {
    return {
      nowclick: 0, //当前菜单
      menus: ["one", "two"], //菜单
      isopen: false,
      notePhoto: [],
      href:'',
      pranddo,
    };
  },
  computed: {
   
  },
  created: function () {},
  methods: {

      //关闭详情
    clooseDt(){
      this.isopen = false;
    },
   //下载传回信息
    toDetail: function(href){
         this.href = href;
         this.isopen = true;
        //  console.log(href)
    },
    //去下载
     download: function(){
        // console.log(this.href);
        window.open(this.href);  
        this.isopen = false; 
    },
   
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#resource {
  min-height: 1200px;
  position: relative;
.awrticle {
  width: 100%;
  height: 100%;
  border: 1px solid red;
}
.one {
  width: 100%;
  margin-top: 12px;
  border-radius: @radius-bg;
  // background: @bg-color-top;
  transition: all @animation-duration-slow;
}
.displays {
  display: none;
}
.notes-photo {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1001;
  overflow: auto;
  .n-p-bg {
    position: fixed;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.9);
    .icon {
      color: #fff;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: @font-size-bg2;
    }
  }
  .n-p-m {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    margin: auto;
    max-height: 96%;
    max-width: 88%;
  }
  
}
}

</style>

