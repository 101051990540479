









import { Component, Vue } from 'vue-property-decorator';

//iconfont图标引入测试
import './static/icon/iconfont';

@Component({
  components: {
  
  },
})
export default class App extends Vue {}
