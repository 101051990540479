<template>
  <div class="notes-photo">
    <div class="n-p-bg" @click="closePh">
      <svg class="icon" aria-hidden="true">
        <use xlink:href="#iconguanbi"></use>
      </svg>
    </div>

    <div class="detail" :style="{ width: width + 'px', display: disp }">
      <div class="article">
        <div class="detail-top">
          <p class="title">{{ detail.name }}</p>
          <p class="styles">
            {{ classlfy[detail.types][detail.classlfy] }} / {{ detail.tep }}
          </p>
          <div class="others">
            <p class="time">{{ detail.time }}</p>
            <div class="msg">
              <p class="zan">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconIcon-zan1"></use>
                </svg>
                {{ likes[0] }}
              </p>
              <p class="look">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconchakanyanjingshishifenxi"></use>
                </svg>
                {{ detail.times }}
              </p>
              <p class="comment">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconIcon-pinglun"></use>
                </svg>
                {{ commentcount }}
              </p>
            </div>
          </div>
        </div>
        <div class="introduc">
          <p>{{ detail.introduc }}</p>
        </div>
        <div :class="cont" v-html="detail.content" ref="detailCont"></div>
      </div>
      <div class="cont-msg">
        <div :class="isLikes[0] != 0 ? 'active' : 'disactive'" @click="praise">
          <svg class="icon" aria-hidden="true" v-if="isLikes[0] == 0">
            <use xlink:href="#iconIcon-zan1"></use>
          </svg>
          <svg class="icon" aria-hidden="true" v-if="isLikes[0] != 0">
            <use xlink:href="#iconIcon-zan"></use>
          </svg>
        </div>
        <p class="names">{{ detail.name }}</p>
        <div class="msg">
          <p class="zan">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconIcon-zan1"></use>
            </svg>
            {{ likes[0] }}
          </p>
          <p class="look">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconchakanyanjingshishifenxi"></use>
            </svg>
            {{ detail.times }}
          </p>
          <p class="comment">
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#iconIcon-pinglun"></use>
            </svg>
            {{ commentcount }}
          </p>
        </div>
      </div>
      <div class="detail-bottom">
        <div class="comments">
          <Comment
            :id="id"
            :count="commentcount"
            @commented="addCount"
          ></Comment>
        </div>
        <div class="bottom-other">
          <otph @detail="detialId" :judge="judge"></otph>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import s from "../static/js/myfunc.js";
import Comment from "./comment";
import otph from "./article.vue";
import { classlfy } from "../static/js/yike";
export default {
  data: function () {
    return {
      detail: "",
      classlfy,
      imgs: [],
      width: 1200,
      disp: "none",
      commentcount: 0, //评论数
      likes: [], //喜欢次数
      isLikes: [],
      cont:'content',
    };
  },
  components: {
    Comment,
    otph,
  },
  props: {
    id: {
      // 文章id
      type: String,
      default: "0",
    },
    judge: {
      // 文章id
      type: Number,
      default: 5,
    },
  },
  computed: {},
  created: function () { 
this.detialId(this.id)  
  },
  // updated() {
  //   let offWidth = document.body.offsetWidth;
  //   if (offWidth > 1680) {
  //     this.width = 1400;
  //   } else {
  //     this.width = offWidth - 240;
  //   }
  //   //字符串化成数组
  //   // var photo = this.$refs.detailCont.textContent.split(",");
  //   // console.log(photo)
  //   this.imgs = this.$refs.detailCont.querySelectorAll("img");
  //   for (let i = 0; i < this.imgs.length; i++) {
  //     this.imgs[i].addEventListener("load", (ele) => {
  //       let e = ele || window.event;
  //       let target = e.target || e.srcElement;
  //       // target.style.maxWidth =  "800px";
  //       //target.style.marginBottom = "30px";
  //       if (target.offsetWidth >= this.width) {
  //         //let mwidth = document.body.offsetWidth*0.8;
  //         target.style.maxWidth = this.width + "px";
  //         target.style.left = -(this.width - 800) / 2 + "px";
  //       } else if (
  //         target.offsetWidth >= 800 &&
  //         target.offsetWidth < this.width
  //       ) {
  //         let lwidth = (target.offsetWidth - 800) / 2;
  //         target.style.left = "-" + lwidth + "px";
  //       }
  //     });
  //   }
  // },
  updated(){
    let offWidth = document.body.offsetWidth;
    if (offWidth > 1680) {
      this.cont='contentb',
      this.width = 1400;
    } else {
      this.cont='content',
      this.width = 1200;
    }
  },
  methods: {
    //关闭
    closePh() {
      this.$emit("clooseDt");
    },
    //获取文章详情
    detailData(e) {
      let that = this;
      that.disp = "none";
      this.$axios
        .post(this.GLOBAL.baseUrl + "/detial", {
          id: e,
        })
        .then(function (res) {
          let data = res.data;
          if (data.status == 200) {
            data.result.time = s.changeTime2(data.result.time);

            that.detail = data.result;
            that.disp = "block";
          } else {
            console.log("访问文章详情后端出错");
          }
        })
        .catch(function (error) {
          console.log("访问文章详情出错");
        });
    },
    //获取评论数
    cmmentCount(e) {
      let that = this;
      this.$axios
        .post(this.GLOBAL.baseUrl + "/commentCount", {
          id: e,
        })
        .then(function (res) {
          let data = res.data;
          // console.log(data)
          if (data.status == 200) {
            that.commentcount = data.result;
          } else {
            console.log("访问文章品论数后端出错");
          }
        })
        .catch(function (error) {
          console.log("访问文章品论数出错");
        });
    },
    //增加评论数
    addCount() {
      this.commentcount++;
    },
    //获取赞数及判断是否已经点赞
    likeCount: function (id, ip, e) {
      this.CommonJs.likeCounts(this, id, ip, e);
      // console.log(this.total)
    },
    //点赞
    praise() {
      if (this.isLikes[0] == 0) {
        this.isLikes.splice(0, 1, 1);
        this.likes[0]++;
        let that = this;
        this.$axios
          .post(this.GLOBAL.baseUrl + "/likeAdd", {
            id: this.id,
            uid: 1,
          })
          .then(function (res) {
            let data = res.data;
            // console.log(data)
            if (data.status == 200) {
              // that.commentcount = data.result;
            } else {
              console.log("添加赞后端出错");
            }
          })
          .catch(function (error) {
            console.log("添加赞出错");
          });
      }
    },
    //切换
    detialId(e) {
      this.detailData(e);
      this.cmmentCount(e);
      this.likeCount(e, 1, this.likes);
      this.likeCount(e, 0, this.isLikes);
    },
  },
};
</script>
<style lang="less">
.detail {
  // width: 1200px;
  margin: @padding-bg auto;
  .article {
    background: @bg-color-top;
    border-radius: @radius-bg @radius-bg 0 0;
    padding: @padding-bg 0;
  }
  .msg {
    display: flex;
    align-self: flex-end;
    color: @font-color-bottom;
    .zan,
    .look {
      padding-right: @padding-sm1;
    }
    .icon {
      padding-right: 2px;
    }
  }
  .detail-top {
    padding: 0 @padding-base @padding-base;
    .title {
      font-size: @font-size-bg3;
      font-weight: 700;
      padding-bottom: @padding-sm1;
    }
    .others {
      display: flex;
      justify-content: space-between;
      color: @font-color-bottom;
    }
    .styles {
      color: @font-color-bottom;
      padding-bottom: 10px;
    }
  }
  .introduc {
    padding: @padding-sm 0;
    background: @bg-color-base;
    p {
      width: 800px;
      margin: 0 auto;
      font-size: @font-size-bg1;
      color: @font-color-base;
      line-height: 1.8;
      padding: @padding-sm;
    }
  }
  .content {
    width: 800px;
    margin: 0 auto;
    p {
      font-size: @font-size-bg1;
      color: @font-color-base;
      line-height: 1.8;
      padding-top: @padding-sm;
      img {
        position: relative;
        width:1200px;
        margin-left:-200px;
        padding: 4px 0;
      }
    }
  }
  .contentb {
    width: 800px;
    margin: 0 auto;
    p {
      font-size: @font-size-bg1;
      color: @font-color-base;
      line-height: 1.8;
      padding-top: @padding-sm;
      img {
        position: relative;
        width:1400px;
        margin-left:-300px;
        padding:0;
      }
    }
  }
}
.cont-msg {
  text-align: center;
  margin: 0 auto;
  padding-bottom: @padding-bg;
  background: @bg-color-top;
  .disactive {
    cursor: pointer;
    display: inline-block;
    width: 120px;
    height: 72px;
    border-radius: 36px;
    background: @primary-color;
    transition: all @animation-duration-slow;
    color: #fff;
    &:hover {
      // background: @primary-5;
    }
    .icon {
      font-size: 48px;
      padding-top: 21px;
    }
  }
  .active {
    display: inline-block;
    box-sizing: border-box;
    width: 120px;
    height: 72px;
    border-radius: 36px;
    border: 1px solid @font-color-bottom;
    transition: all @animation-duration-slow;
    color: @font-color-base;
    .icon {
      color: @primary-color;
    }
    .icon {
      font-size: 48px;
      padding-top: 21px;
    }
  }
  .names {
    font-size: @font-size-bg3;
    padding-top: @padding-sm;
    padding-bottom: 8px;
    font-weight: 700;
  }
  .msg {
    justify-content: center;
  }
}
.detail-bottom {
  display: flex;
  flex-direction: row;
  background: #eee;
  background: @bg-color-base;
  padding: @padding-base;
  border-radius: 0 0 @radius-bg @radius-bg;
  .comments {
    flex: 1;
    padding: @padding-base;
    background: @bg-color-top;
    margin-right: @padding-base;
    border-radius: @radius-bg;
    transition: all @animation-duration-slow;
  }
  .bottom-other {
    flex: none;
    width: 390px;
    height: 560px;
    padding: 30px 30px 0;
    box-sizing: border-box;
    border-radius: @radius-bg;
    background: @bg-color-top;
    transition: all @animation-duration-slow;
    overflow: auto;
  }
}
.notes-photo {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1001;
  overflow: auto;
  .n-p-bg {
    position: fixed;
    top: 0;
    z-index: -1;
    height: 100%;
    width: 100%;
    background: rgba(0,0,0,0.9);
    .icon {
      color: #fff;
      position: absolute;
      right: 10px;
      top: 10px;
      font-size: @font-size-bg2;
    }
  }
  .n-p-m {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
    margin: auto;
    max-height: 96%;
    max-width: 88%;
  }
  
}
</style>