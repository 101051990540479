<template>
  <div id="resou" ref="photo">
    <div class="card">
      <div
        class="li-div"
        v-for="(rem, index) in articles"
        :key="index"
      >
        <div class="photo-img">
          <img
            :src="GLOBAL.baseUrl + '/download/' + rem.icon"
          />
        </div>
        <div class="msg">
          <p class="title">{{ rem.name }}</p>
        </div>
        <div class="work-other">
          <p class="dates">{{ classlfy[rem.types][rem.classlfy] }}格式文件</p>
          <p class="type">
            <span class="fenge">{{ rem.tep }}</span>
          </p>
          <div class="down">
            <p class="look">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#iconyunduanxiazai"></use>
              </svg>
              {{ rem.times }}
            </p>
          </div>
        </div>
        <p class="tqm">{{rem.introduc}}</p>
        <p class="download" @click="detail(rem._id,rem.content)">下载</p>
        
      </div>
    </div>
    <div class="clear"></div>
    <div v-if="articles.length == 0" class="none">
      <p>还没有内容～</p>
    </div>
    <div class="mores">{{ tobottom }}</div>
  </div>
</template>
<script type="text/javascript">
import s from "../static/js/myfunc.js";
import elementR from "element-resize-detector";
import { classlfy } from "../static/js/yike";
export default {
  data() {
    return {
      articles: [],
      art: [],
      judge: 4,
      nowpage: 1,
      gjc: -1,
      total: [],
      tobottom: "",
      classlfy,
      downloads:[],
    };
  },
  components: {},
  props: {
    display: {
      // 每页显示条数
      type: Number,
      default: 10,
    },
  },
  computed: {},
  created: function () {
    this.datas(this.nowpage);
    this.totals();
  },
  methods: {
    //时间转换
    dtime: function (time) {
      return s.changeTime2(time);
    },
    datas: function (nowpg) {
      //数据项
      // this.articles = [];
      this.CommonJs.content(
        this,
        nowpg,
        this.display,
        this.judge,
        this.gjc,
        this.articles
      );
    },
    totals: function () {
      this.CommonJs.counts(this, this.gjc, this.judge, this.total);
    },
    //点击进入详情
    detail: function (id,href) {
      let _this = this;
      _this.$axios
        .post(_this.GLOBAL.baseUrl + "/timesAdd", {
          id: id,
        })
        .then(function (res) {
          var data = res.data;
          if (data.status == 200) {
          } else {
            console.log("访问详情后端出错");
          }
        })
        .catch(function (error) {
          // console.log(error);
          console.log("访问详情数出错");
        });
      _this.$emit("detail", href);
    },
     //获取下载数
    likeCount: function (id, e) {
      this.CommonJs.likeCounts(this, id, 1, e);
    },
    //点击下载
    praise() {
      if (this.isLikes[0] == 0) {
        this.isLikes.splice(0, 1, 1);
        this.likes[0]++;
        let that = this;
        this.$axios
          .post(this.GLOBAL.baseUrl + "/likeAdd", {
            id: this.id,
            uid: 1,
          })
          .then(function (res) {
            let data = res.data;
            // console.log(data)
            if (data.status == 200) {
              // that.commentcount = data.result;
            } else {
              console.log("添加赞后端出错");
            }
          })
          .catch(function (error) {
            console.log("添加赞出错");
          });
      }
    },
    
    //加载更多
    morepage: function () {
      var scrolltop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrolltop + window.innerHeight >= document.body.offsetHeight - 100) {
        if (this.nowpage < Math.ceil(this.total[0] / this.display + 1)) {
          this.tobottom = "";
          this.nowpage++;
          this.datas(this.nowpage);
        } else {
          this.tobottom = "已到底部...";
        }
      }
    },
  },
  mounted() {
    s.addEvent(window, "scroll", this.morepage);
  },
};
</script>
<style lang="less" scoped>
#resou {
  width: 100%;
  padding-bottom: @padding-base;
  .card {
    margin: 0 auto;
    display: inline-block;
    .li-div {
      display: block;
      text-decoration: none;
      overflow: hidden;
      background: @bg-color-top;
      width: 280px;
      height: 400px;
      float: left;
      box-sizing: border-box;
      margin-right: 30px;
      margin-bottom: @padding-base;
      border-radius: @radius-sm;
      position: relative;
      transition: all @animation-duration-slow;
      &:hover{
        box-shadow: 0px 10px 20px rgba(16,18,20,0.06);
      }
    }
    .photo-img {
      height: 210px;
      overflow: hidden;
      img {
        height: 210px;
      }
    }
    .msg {
      padding: 30px 20px 0px 20px;
      font-size: @font-size-bg2;
      font-weight: 700;
      overflow: hidden;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
    }
    .download{
      height: 40px;
      width: 80px;
      border-radius: 20px;
      background: @primary-color;
      color: #fff;
      text-align: center;
      line-height: 40px;
      position: absolute;
      right: 12px;
      bottom: 12px;
      text-decoration: none;
      cursor: pointer;
    }
    .tqm{
      font-size: 24px;
      font-weight: 700;
      color: @bg-color-bottom;
      line-height: 40px;
      position: absolute;
      right: 100px;
      bottom: 12px;
    }
  }

  .mores {
    text-align: center;
    padding-top: @padding-base;
    color: @font-color-bottom;
  }
  .work-other {
    padding: 8px @padding-sm ;

    width: 100%;
    p {
      font-size: @font-size-sm;
      transition: all @animation-duration-slow;
    }
    .type {
      color: @font-color-base;
      padding-top: 4px;
    }
    .dates {
      flex: 1;
      color: @font-color-bottom;
    }
    .down {
      display: flex;
      align-self: flex-end;
      color: @font-color-bottom;
      position: absolute;
      bottom: 20px;
      left: 20px;
    }
    .zan {
      padding-right: @padding-sm1;
    }
    .icon {
      padding-right: 2px;
    }
  }
}
</style>