<template>
  <div id="aritcle" ref="article">
    <div :class="carcClass" class="card">
      <div
        class="li-div"
        v-for="(rem, index) in articles" :key="index"
        @click="detail(rem._id)"
      >
        <div class="photo-img"><img :src="GLOBAL.baseUrl+'/cover/' + rem.icon"/></div>
        <div class="introduce-div">
          <p class="title">{{ rem.name }}</p>
          <p class="introduce">{{ rem.introduc }}</p>
          <div class="work-other">
            <p class="type">
              <span>{{ classlfy[rem.types][rem.classlfy] }}</span>
              <span class="fen">/</span>
              <span class="fenge">{{ rem.tep }}</span>
            </p>
            <p class="dates">{{ dtime(rem.time) }}</p>
            <div class="msg">
              <p class="zan">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconIcon-zan1"></use>
                </svg>
                {{rem.likes}}
              </p>
               <p class="look">
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#iconchakanyanjingshishifenxi"></use>
                </svg>
                {{rem.times}}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
     <div v-if="articles.length == 0" class="none">
      <p>还没有内容～</p>
    </div>
    <div class="mores">{{tobottom}}</div>
    <!-- <Pages :total="total[0]" :nowpage='nowpage' :display="display" @pagechange="pagechange"/> -->
  </div>
</template>
<script type="text/javascript">
import s from "../static/js/myfunc.js";
import Pages from './pages.vue';
import elementR from 'element-resize-detector';
import { classlfy } from "../static/js/yike";
export default {
  data() {
    return {
      articles: [],
      gjc: -1,
      total: [],
      carcClass:'one',
      tobottom: '',
      classlfy,
      nowpage: 1,         // 当前页数
      judge: 5,           // 5为文章，6为项目
    };
  },
  components: {
    Pages,
  },
  props: {
    display: {
      // 每页显示条数
      type: Number,
      default: 8,
    },
  },
  computed: {
  },
  created: function () {
    this.datas(this.nowpage);
    this.totals();
  },
  methods: {
    //时间转换
    dtime: function (time) {
      return s.changeTime2(time);
    },
    datas:function(nowpg){
      //数据项
      this.CommonJs.content(this,nowpg,this.display,this.judge,this.gjc,this.articles)
    },
    totals: function(){
      this.CommonJs.counts(this,this.gjc,this.judge,this.total);
      // console.log(this.total)
    },
     pagechange: function(nowp){
       this.datas(nowp);
     },
    //点击进入详情
    detail: function(id){
      let _this = this;
      _this.$axios
        .post(_this.GLOBAL.baseUrl + "/timesAdd", {
          id: id,
        })
        .then(function (res) {
          var data = res.data;
          if (data.status == 200) {
            
          } else {
           console.log("访问详情后端出错");
          }
        })
        .catch(function (error) {
          // console.log(error);
          console.log("访问详情数出错");
        });
      _this.$emit('detail',id)
    },
    //监听元素宽度变化
    watchSize(){
      const _this = this;
      const erd = elementR();
      erd.listenTo(this.$refs.article, (element) => {
        var width = element.offsetWidth;
        var height = element.offsetHeight;
        _this.$nextTick(() => { // 这里填写监听改变后的操作
          if(width>400){
            this.carcClass='one';
          }else{
            this.carcClass='two';
          }
        });
      });
    },
    //加载更多
    morepage: function(){
      var scrolltop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      if(scrolltop + window.innerHeight >= document.body.offsetHeight-100) {
        if(this.nowpage<Math.ceil(this.total[0]/this.display+1)){
          this.tobottom = "";
          this.nowpage++;
          this.datas(this.nowpage);
        }else{
          this.tobottom = "已到底部...";
        }
          
      }
    },
  },
  mounted() {
    this.watchSize();s.addEvent(window,'scroll',this.morepage);
  },

};
</script>
<style lang="less" scoped>
@imgwidth: var(--imgWidth, 210px);
@imgheight: var(--imgHeight, 160px);
@disintroduce: var(--disIntroduce,-webkit-box);
#aritcle {
  // background: #999;
  width: 100%;
  padding-bottom: @padding-base;
}
.card{
  .li-div{
  display: flex;
  cursor: pointer;
  border-radius: @radius-bg;
  background: @bg-color-top;
  text-decoration: none;
  transition: all @animation-duration-slow;
}
  .photo-img{
    flex: none;
    overflow: hidden;
  }
  .introduce-div{
    display: flex;
    width: 100%;
    flex-direction:column;
    .title{
      font-weight: 700;
      color: @font-color-top;
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      color: @font-color-base;
      transition: all @animation-duration-slow;
    }
    .work-other{
      display:flex;
      justify-content:space-between;
      width: 100%;
      p{
        font-size: @font-size-sm;
        transition: all @animation-duration-slow;
      }
      .type{
        color: @font-color-base;
      }
      .dates{
        flex: 1;
        color: @font-color-bottom;
      }
      .msg{
        display: flex;
        align-self:flex-end;
        color: @font-color-bottom;
      }
      .zan{
        padding-right: @padding-sm1;
      }
      .icon{
        padding-right: 2px;
      }
    }
  }
}
.one{
  .li-div{
    padding: @padding-sm;
    margin-bottom: @padding-base;
  }
  .photo-img{
    width: 210px;
    height: 160px;
    border-radius: @radius-base;
    img{
      height: 160px;
    }
  }
  .introduce-div{
    align-content:flex-start;
    padding-left: @padding-base;
    justify-content: flex-start;
    .title{
      // flex-grow:0;
      font-size: @font-size-bg2;
    }
    .introduce{
      padding-top: @padding-sm1;
      overflow : hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      color: @font-color-base;
      transition: all @animation-duration-slow;
    }
    .work-other{
      flex: 1;
      p{
        align-self:flex-end;
      }
      .type{
        padding-right: @padding-sm1;
      }
    }
  }
}
.two{
   .li-div{
    margin-bottom: @padding-sm;
  }
  .photo-img{
    width: 120px;
    height: 92px;
    border-radius: @radius-sm;
    img{
      height: 92px;
    }
  }
  .introduce-div{
    padding-left: @padding-sm;
    justify-content: space-between;
    .title{
      font-size: @font-size-base;
    }
    .introduce{
      display: none;
    }
    .work-other{
      position: relative;
      flex-wrap: wrap;
      flex-direction:row;
      width: 100%;
      p{
        font-size: @font-size-sm;
      }
      .type{
        padding-right: @padding-sm1;
        padding-bottom: 6px;
        width: 100%;
      }
      .msg{
        position: absolute;
        right: 0px;
        bottom: 0;
      }
    }
  }
}
.mores{
  text-align: center;
  padding-top: @padding-base;
  color: @font-color-bottom;
}
</style>