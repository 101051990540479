import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

//引入主题
import {menusModel} from '../theme/model';

export default new Vuex.Store({
  state: {
    themeA: menusModel.themeA,
    themeB: menusModel.themeB,
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
