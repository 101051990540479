<template>
  <div class="about">
    <h1>还在构思中...</h1>
    <button @click="goHome">返回首页</button>
  </div>
</template>
<script>
export default {
    methods: {
      //跳转about
      goHome(){
        this.$router.push('/');
      }
    },
  };
</script>
<style scoped lang="less">
  .h1{
     background-color: @bg-color-base;
  }
  .about{
    height: 100%;
  }
</style>
