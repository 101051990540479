<template>
  <div class="mreward">
    <!-- 打赏页面 -->
    <div class="dashang">
      <svg class="icon" aria-hidden="true" @click="clooser()">
        <use xlink:href="#iconguanbi1"></use>
      </svg>
      <p class="ds-header">休息一下</p>
      <p class="ds-js">看在这个作者分享不易上，请休息一下吧🥺…</p>
      <div class="ds-img">
        <img src="../../static/images/wx1.png" />
        <img src="../../static/images/zfb1.png" />
      </div>
      <div class="ds-btn">
        <p class="ds-btn-no" @click="goto">直接跳过</p>
        <p class="ds-btn-yes" @click="reward">支持 {{ dscs[0] }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      dscs: [],
    };
  },
  props: {
    id: {
      // 文章id
      type: String,
      default: '0',
    },
    judge: {
      // 文章id
      type: Number,
      default: 5,
    },
  },
  created: function() {
    this.getds();
  },
  methods: {
    //关闭窗口
    clooser() {
      this.$emit('clooseDt');
    },
    //出发按钮
    goto() {
      this.$emit('goto');
    },
    //点击大赏
    reward() {
      this.CommonJs.getVisit(this, 3);
      this.$message.success('感谢您的支持～');
      this.goto();
    },
    //获取大赏次数
    getds() {
      this.CommonJs.countVisit(this, 3, this.dscs);
    },
  },
};
</script>
<style scoped lang="less">
.mreward {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1001;

  .dashang {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    text-align: center;
    background: @bg-color-top;
    .icon {
      font-size: 28px;
      color: @font-color-bottom;
      position: absolute;
      right: 24px;
      top: 16px;
      cursor: pointer;
    }
    .ds-header {
      font-size: 24px;
      color: @font-color-top;
      padding-top: 72px;
      font-weight: 700;
    }
    .ds-js {
      color: @font-color-bottom;
      padding-top: 24px;
      padding-bottom: 40px;
      width: 72%;
      display: inline-block;
    }
    .ds-img {
      display: flex;
      justify-content: center;
      img {
        width: 42%;
        margin: 8px;
      }
    }
    .ds-btn {
      position: absolute;
      bottom: 0px;
      width: 100%;
      display: flex;
      justify-content: center;
      cursor: pointer;
      p {
        height: 48px;
        border-radius: 24px;
        padding: 0 24px;
        line-height: 48px;
        margin: 12px;
        font-weight: 700;
      }
      .ds-btn-no {
        border: 1px solid @font-color-bottom;
        color: @font-color-bottom;
      }
      .ds-btn-yes {
        color: #fff;
        background: @primary-color;
      }
    }
  }
}
</style>
