<template>
  <!-- 个人分享 -->
  <div id="resource">
    <div class="article">
      <resour
        name="Prod"
        ref="child2"
        @detail="toDetail"
      ></resour>
    </div>
    <Top></Top>
    <reward v-if="isopen" @clooseDt="clooseDt()" @goto="download"></reward>
  </div>
</template>

<script>
import { pranddo } from "../../static/js/yike";
import Top from "../../components/top";
import Details from "../../components/mobile/details";
import resour from "../../components/mobile/resources.vue"
import reward from "../../components/mobile/reward.vue";
export default {
  name: "Home",
  components: {
    Top,
    Details,
    resour,
    reward,
  },
  data: function () {
    return {
      nowclick: 0, //当前菜单
      menus: ["one", "two"], //菜单
      isopen: false,
      notePhoto: [],
      href:'',
      pranddo,
    };
  },
  computed: {
   
  },
  created: function () {},
  methods: {

      //关闭详情
    clooseDt(){
      this.isopen = false;
    },
   //下载传回信息
    toDetail: function(href){
         this.href = href;
         this.isopen = true;
         console.log(href)
    },
    //去下载
     download: function(){
        console.log(this.href);
        window.open(this.href);  
        this.isopen = false; 
    },
   
  },
  mounted() {},
};
</script>

<style lang="less" scoped>
#resource {
padding: 72px 24px 30px;

}

</style>

