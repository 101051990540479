<template>
  <div id="product" ref="photo">
    <div class="card one">
      <div
        class="li-div"
        v-for="(rem, index) in articles"
        :key="index"
        v-if="index < 3"
        @click="detail(rem._id)"
      >
        <div class="img-main">
          <div class="msg">
            <p class="title">{{ rem.name }}</p>
          </div>
          <div class="photo-img">
            <img
              :src="GLOBAL.baseUrl + '/cover/' + rem.icon"
              :style="{ height: height1 + 'px' }"
            />
          </div>
        </div>
        <div class="work-other">
          <p class="type">
            <span>{{ classlfy[rem.types][rem.classlfy] }}</span>
            <span class="fen">/</span>
            <span class="fenge">{{ rem.tep }}</span>
          </p>
          <p class="dates">{{ dtime(rem.time) }}</p>
          <div class="msg">
            <p class="zan">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#iconIcon-zan1"></use>
              </svg>
              {{ rem.likes }}
            </p>
            <p class="look">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#iconchakanyanjingshishifenxi"></use>
              </svg>
              {{ rem.times }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="card two">
      <div
        class="li-div"
        v-for="(rem, index) in articles"
        v-if="index >= 3"
        :key="index"
        @click="detail(rem._id)"
      >
        <div class="img-main">
          <div class="msg">
            <p class="title">{{ rem.name }}</p>
          </div>
          <div class="photo-img">
            <img
              :src="GLOBAL.baseUrl + '/cover/' + rem.icon"
              :style="{ height: height2 + 'px' }"
            />
          </div>
        </div>
        <div class="work-other">
          <p class="type">
            <span>{{ classlfy[rem.types][rem.classlfy] }}</span>
          </p>
          <p class="dates">{{ dtime(rem.time) }}</p>
          <div class="msg">
            <p class="zan">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#iconIcon-zan1"></use>
              </svg>
              {{ rem.likes }}
            </p>
            <p class="look">
              <svg class="icon" aria-hidden="true">
                <use xlink:href="#iconchakanyanjingshishifenxi"></use>
              </svg>
              {{ rem.times }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div class="clear"></div>
    <div v-if="articles.length == 0" class="none">
      <p>还没有内容～</p>
    </div>
    <div class="mores">{{ tobottom }}</div>
  </div>
</template>
<script type="text/javascript">
import s from "../static/js/myfunc.js";
import elementR from "element-resize-detector";
import { classlfy } from "../static/js/yike";
export default {
  data() {
    return {
      articles: [],
      art: [],
      judge: 6,
      nowpage: 1,
      gjc: -1,
      total: [],
      tobottom: "",
      height1: 120,
      height2: 120,
      classlfy,
    };
  },
  components: {},
  props: {
    display: {
      // 每页显示条数
      type: Number,
      default: 10,
    },
  },
  computed: {},
  created: function () {
    this.datas(this.nowpage);
    this.totals();
  },
  methods: {
    //时间转换
    dtime: function (time) {
      return s.changeTime2(time);
    },
    datas: function (nowpg) {
      //数据项
      // this.articles = [];
      this.CommonJs.content(
        this,
        nowpg,
        this.display,
        this.judge,
        this.gjc,
        this.articles
      );
    },
    totals: function () {
      this.CommonJs.counts(this, this.gjc, this.judge, this.total);
    },
    //点击进入详情
    detail: function (id) {
      let _this = this;
      _this.$axios
        .post(_this.GLOBAL.baseUrl + "/timesAdd", {
          id: id,
        })
        .then(function (res) {
          var data = res.data;
          if (data.status == 200) {
          } else {
            console.log("访问详情后端出错");
          }
        })
        .catch(function (error) {
          // console.log(error);
          console.log("访问详情数出错");
        });
      _this.$emit("detail", id);
    },
    //监听元素宽度变化
    watchSize() {
      const _this = this;
      const erd = elementR();
      erd.listenTo(this.$refs.photo, (element) => {
        let width = element.offsetWidth;
        this.height1 = (width - 60) / 4;
        this.height2 = ((width - 90) / 16) * 3;
        _this.$nextTick(() => {
          // 这里填写监听改变后的操作
          if (width > 400) {
            this.carcClass = "one";
          } else {
            this.carcClass = "two";
          }
        });
      });
    },
    //加载更多
    morepage: function () {
      var scrolltop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (scrolltop + window.innerHeight >= document.body.offsetHeight - 100) {
        if (this.nowpage < Math.ceil(this.total[0] / this.display + 1)) {
          this.tobottom = "";
          this.nowpage++;
          this.datas(this.nowpage);
        } else {
          this.tobottom = "已到底部...";
        }
      }
    },
  },
  mounted() {
    this.watchSize();
    s.addEvent(window, "scroll", this.morepage);
  },
};
</script>
<style lang="less" scoped>
#product {
  width: 100%;
  padding-bottom: @padding-base;
  .card {
  overflow: hidden;
  .li-div {
    display: block;
    text-decoration: none;
    overflow: hidden;
  }
  .img-main {
    position: relative;
    border-radius: @radius-sm;
    overflow: hidden;
    img {
      width: 100%;
    }
    .msg {
      position: absolute;
      bottom: -2px;
      opacity: 0;
      width: 100%;
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      color: #fff;
      background-image: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
      transition: all @animation-duration-slow;
      .title {
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }
    &:hover .msg {
      opacity: 1;
    }
  }
}
.one {
    width: 100%;
    padding-bottom: @padding-base;
  column-count: 3;
  -webkit-column-count: 3;
  -moz-column-count: 3;
  -moz-column-gap: @padding-base;
  -webkit-column-gap: @padding-base;
  column-gap: @padding-base;
  margin: auto;
 
  .img-main {
    .msg {
      padding: @padding-sm1;
      .title {
        font-size: @font-size-bg1;
      }
    }
  }
}
.two {
  .li-div {
      width: 25%;
      float: left;
      box-sizing: border-box;
      padding: 0 15px;
    margin-bottom: @padding-base;
    &:nth-child(4n){
        padding: 0  0 0 15px;
    }
    &:nth-child(4n+1){
        padding: 0 15px 0 0;
    }
  }
  .img-main {
    .msg {
      padding: 12px;
      .title {
        font-size: @font-size-bg1;
      }
    }
  }
}
.mores {
  text-align: center;
  padding-top: @padding-base;
  color: @font-color-bottom;
}
.work-other {
  padding-top: @padding-sm1;
  display: flex;
  justify-content: space-between;
  width: 100%;
  p {
    font-size: @font-size-sm;
    transition: all @animation-duration-slow;
  }
  .type {
    color: @font-color-base;
  }
  .dates {
    padding-left: @padding-sm1;
    flex: 1;
    color: @font-color-bottom;
  }
  .msg {
    display: flex;
    align-self: flex-end;
    color: @font-color-bottom;
  }
  .zan {
    padding-right: @padding-sm1;
  }
  .icon {
    padding-right: 2px;
  }
}
}

</style>