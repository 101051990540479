<template>
  <div class="comment">
    <div class="comment-input">
      <div class="user-header" @click="selectH">
        <img :src="pharr[nowheader].path" />
        <p class="change-head">快点我</p>
      </div>
      <div class="input-main">
        <textarea
          class="text inputs"
          placeholder="对内容有什么看法？"
          v-model="comment"
        ></textarea>
        <div class="input-bt">
          <input class="name inputs" type="text" v-model="name" />
          <button
            class="submit"
            :class="submitOk ? 'ok' : 'no'"
            @click="submit"
          >
            评论
          </button>
        </div>
        <div class="header-img" v-if="selectheader">
          <p
            class="img-li"
            v-for="(item, index) in pharr"
            :key="index"
            :class="index == nowheader ? 'active' : ''"
            @click="selected(index)"
          >
            <img :src="item.path" />
          </p>
        </div>
      </div>
    </div>
    <div class="comment-list" v-for="(item, index) in commentList" :key="index">
      <div class="user-header">
        <img :src="item.icon" />
      </div>
      <div class="input-main">
        <div class="name-time">
          <span class="name">{{ item.name }}</span
          ><span class="tip"></span><span class="time">{{ item.time }}</span>
        </div>
        <p class="comment-main">
          {{ item.comment }}
        </p>
      </div>
    </div>
    <p class="mores istrue" v-if="!isbottom" @click="morepage">
      点击加载更多评论
    </p>
    <p class="mores isfalse" v-if="isbottom">没有更多评论</p>
  </div>
</template>
<script>
import s from "../static/js/myfunc.js";
export default {
  data: function () {
    return {
      name: "匿名", //评论者名称
      comment: "", //评论内容
      num: 1, //当前页面
      display: 6, //单页条数
      isbottom: false, //是否加载结束
      commentList: [], //评论内容
      pharr: [], //头像
      nowheader: 0, //当前选择头像
      selectheader: false, //选择头像
    };
  },
  props: {
    id: {
      // 文章id
      type: String,
      default: "0",
    },
    count: {
      // 评论数
      type: Number,
      default: "0",
    },
  },
  computed: {
    submitOk: function () {
      if (this.comment.length > 0) {
        return true;
      } else {
        return false;
      }
    },
  },
  created: function () {
    this.getComment();
    this.showPhoto("./data/avatar/");
  },
  methods: {
    //获取头像
    showPhoto: function (path) {
      var _this = this;
      _this.$axios
        .post(_this.GLOBAL.baseUrl + "/showphoto", { path: path })
        .then(function (res) {
          var data = res.data;
          if (data.status === 200) {
            _this.pharr = [];
            var patt1 = /\.(\w+)/;
            var ver = data.result;
            //获取文件数
            for (var i = 0; i < ver.length; i++) {
              var aa = ver[i].filename.match(patt1)[1];
              if (aa == "jpg" || aa == "png" || aa == "jpeg") {
                ver[i].path =
                  _this.GLOBAL.baseUrl + "/avatar/" + ver[i].filename;
                _this.pharr.push(ver[i]);
                // console.log(_this.pharr)
              }
            }
          } else {
            console.log("获取头像后端失败");
          }
        })
        .catch(function (error) {
          console.log("获取头像失败");
        });
    },
    //弹出头像选择框
    selectH() {
      this.selectheader = !this.selectheader;
    },
    //选择头像
    selected(e) {
      this.nowheader = e;
      this.selectheader = false;
    },
    //分页获取评论
    getComment: function () {
      var _this = this;
      _this.$axios
        .post(_this.GLOBAL.baseUrl + "/comment", {
          id: _this.id,
          nowpage: _this.num,
          display: _this.display,
        })
        .then(function (res) {
          var data = res.data;
          if (data.status == 200) {
            _this.num++;
            for (let i = 0; i < data.result.length; i++) {
              data.result[i].time = s.detiaTime(data.result[i].time);
              data.result[i].icon =
                _this.GLOBAL.baseUrl + "/avatar/" + data.result[i].icon;
              _this.commentList.push(data.result[i]);
              // console.log(_this.commentList);
            }
          } else {
            _this.$message.error("访问评论后端出错");
          }
        })
        .catch(function (error) {
          // console.log(error);
          _this.$message.error("访问评论出错");
        });
    },
    //加载更多
    morepage: function () {
      console.log("a");
      if ((this.num - 1) * this.display < this.count) {
        this.getComment();
      } else {
        this.isbottom = true;
      }
    },
    //上传评论
    //很高兴，再一次遇到你，今天能看到你实属不易。为什么呢？因为时隔这么久。很多东西都淡忘了。
    submit: function () {
      if (this.comment.length > 0) {
        let _this = this,
          cname = "匿名";
        if (_this.name.length > 0) {
          cname = _this.name;
        }
        let data = {
          comment: _this.comment,
          name: cname,
          pic: _this.pharr[_this.nowheader].filename,
          icon: _this.pharr[_this.nowheader].path,
          id: _this.id,
          uid: 1,
          time: s.detiaTime(new Date()),
        };
        _this.comment = "";
        _this.commentList.unshift(data);
        _this.$axios
          .post(_this.GLOBAL.baseUrl + "/upcomment", data)
          .then(function (res) {
            var data = res.data;
            if (data.status == 200) {
              //alert('评论成功！')
              _this.$emit("commented");
            }else {
            _this.$message.error("添加评论后端出错");
          }
        })
        .catch(function (error) {
          _this.$message.error("添加评论出错");
        });
      }
    },
  },
  watch: {
    //限制输入长度
    name() {
      if (this.name.length > 12) {
        this.name = String(this.name).slice(0, 12);
      }
    },
    //限制输入长度
    comment() {
      if (this.comment.length > 320) {
        this.comment = String(this.comment).slice(0, 320);
      }
    },
  },
};
</script>
<style lang="less" scoped>
.comment {
  .user-header {
    margin-right: @padding-sm;
    img {
      width: 100%;
       width: 48px;
    height: 48px;
    border-radius: 50%;
    overflow: hidden;
    background: @bg-color-bottom;
    }
    .change-head{
      padding-top: 8px;
      text-align: center;
      color: @font-color-bottom;
    }
  }
  .input-main {
    flex: 1;
    // background: @bg-color-bottom;
  }
  .comment-input {
    position: relative;
    display: flex;
    padding-bottom: @padding-bg;
    .user-header {
      cursor: pointer;
    }
    .inputs {
      box-sizing: border-box;
      border-radius: @radius-sm;
      background: @bg-color-other;
      border: 0;
      outline: none;
      padding: @padding-sm1;
      resize: none;
      color: @font-color-base;
      font-size: @font-size-base;
      border: 1px solid @bg-color-other;
      transition: all @animation-duration-slow;
      &:focus {
        border: 1px solid @font-color-top;
        background: @bg-color-top;
      }
    }
    .text {
      width: 100%;
      height: 106px;
      &:focus {
        border: 1px solid @font-color-top;
        background: @bg-color-top;
      }
    }
    .input-bt {
      padding-top: 6px;
      display: flex;
      justify-content: space-between;
      .name {
        height: 42px;
        width: 196px;
      }
      .submit {
        width: 80px;
        height: 42px;
        border-radius: 31px;
        border: 0;
        outline: none;
        background: @primary-color;
        transition: all @animation-duration-slow;
        color: #fff;
        &:hover {
          // background: @primary-5;
        }
      }
      .ok {
        cursor: pointer;
        background: @primary-color;
        &:hover {
          // background: @primary-5;
        }
      }
      .no {
        cursor: not-allowed;
        background: @font-color-bottom;
        &:hover {
          background: @font-color-bottom;
        }
      }
    }
    .header-img {
      display: flex;
      flex-wrap: wrap;
      padding: @padding-sm1;
      border-radius: @radius-sm;
      // width: 280px;
      position: absolute;
      box-shadow: 0 6px 24px rgba(38, 46, 95, 0.1);
      top: 60px;
      left: 0;
      background: @bg-color-top;
      .img-li {
        cursor: pointer;
        width: 36px;
        height: 36px;
        border-radius: 50%;
        margin: 7px;
        overflow: hidden;
        opacity: 0.5;
        transition: all @animation-duration-slow;
        img {
          width: 100%;
        }
        &:hover {
          opacity: 1;
        }
      }
      .active {
        opacity: 1;
      }
    }
  }
  .comment-list {
    display: flex;
    padding-bottom: @padding-bg;
    .name-time {
      padding-bottom: 4px;
      .name {
        font-weight: 700;
      }
      .tip {
        display: inline-block;
        vertical-align: middle;
        margin: 6px;
        width: 2px;
        height: 2px;
        border-radius: 50%;
        background: @font-color-bottom;
      }
      .time {
        font-size: @font-size-sm;
        color: @font-color-bottom;
      }
    }
    .comment-main {
      color: @font-color-base;
    }
  }
  .mores {
    text-align: center;
    color: @font-color-bottom;
  }
  .istrue {
    cursor: pointer;
  }
}
</style>