import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Index from '../views/Index.vue';
import Home from '../views/Home.vue';
import Share from '../views/Share.vue';
import Product from '../views/Product.vue';
import Resource from '../views/Resource.vue';
import Exchange from '../views/Exchange.vue';

//移动端
import mIndex from '../views/mobile/Index.vue';
import mHome from '../views/mobile/Home.vue';
import mShare from '../views/mobile/Share.vue';
import mProd from '../views/mobile/Product.vue';
import mResource from '../views/mobile/Resource.vue';

import mprod from '../components/mobile/product.vue';

//组件引入
import Article from '../components/article.vue';
import Photog from '../components/photography.vue';
import Notes from '../components/notes.vue';
import Prod from '../components/product.vue';
// import { BackTop } from '_ant-design-vue@1.7.2@ant-design-vue';

Vue.use(VueRouter);

const routes: RouteConfig[] = [
  {
    path: '/',
    name: 'Home',
    //redirect: '/home',
    component: Index,
    children: [
      {
        path:'',
        component: Home
      },
      {
        path:'share',
        component: Share,
        children:[ {path: '',components:{Article,Photog,Notes}}]
      },
      {
        path:'product',
        component: Product,
        children:[ {path: '',components:{Prod}}]
      },
      {
        path:'resource',
        component: Resource,
      },
      {
        path:'exchange',
        component: Exchange,

      }
    ]
  },
  {
    //移动端
    path: '/m',
    name: 'mHome',
    //redirect: '/home',
    component: mIndex,
    children: [
      {
        path:'',
        component: mHome
      },
      {
        path:'share',
        component: mShare,
        children:[ {path: '',components:{Article,Photog,Notes}}]
      },
      {
        path:'product',
        component: mProd,
        children:[ {path: '',components:{mprod}}]
      },
      {
        path:'resource',
        component: mResource,
      },
      {
        path:'exchange',
        component: Exchange,

      }
    ]
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/m/about',
    name: 'mAbout',
    component: () => import(/* webpackChunkName: "about" */ '../views/mobile/About.vue'),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to,from,next) => {
  // window.scrollTo(0,0);
  if(window.navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)){
    // console.log('屏幕测试1')
    // return
  }else{
    // console.log('屏幕测试2');
    // window.location.href = '/#/';
    // return;
    // router.push({ path: "/mlogin" });
  }
  next();
})

export default router;
